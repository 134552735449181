import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataProvider, RequestOptions, StrapiService } from '../../core';
import { Request } from './request.model';

@Injectable()
export class RequestsService extends StrapiService<Request> {
	protected override model = 'requests';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}
}
