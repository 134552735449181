import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthState } from '@portals/store';
import { CookieService } from 'ngx-cookie';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const InterceptorSkipHeader = 'x-skip-interceptor';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
	constructor(private user: AuthState, private cookieService: CookieService, private router: Router) {}

	// intercept request and add token
	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const req = request.headers.has(InterceptorSkipHeader)
			? request.clone({
					headers: request.headers.delete(InterceptorSkipHeader),
			  })
			: request.clone({
					headers: request.headers.set('Authorization', `Bearer ${localStorage.getItem('auth-key')}`),
			  });

		return next.handle(req).pipe(
			catchError((error) => {
				error?.status === 401 && this.user.logout();

				return throwError(error);
			})
		);
	}
}
