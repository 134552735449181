import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsDataPluginModule } from '@angular-ru/ngxs';
import { NGXS_DATA_STORAGE_PLUGIN } from '@angular-ru/ngxs/storage';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';

import { environment } from '@portals/environments';
import { PortalsApiModule } from '@portals/api';

import { CORE_STATES } from './states';

@NgModule({
	imports: [
		PortalsApiModule.forRoot({ environment }),
		NgxsStoragePluginModule.forRoot({
			storage: StorageOption.SessionStorage,
			key: ['collectibles.mutedUsers', 'collectibles.filters', 'collectibles.filterParameter', 'notifications.filters', 'notifications.filterParameter', 'articles.filters', 'articles.filterParameter', 'users.filters', 'users.filterParameter', 'projects.filters', 'projects.filterParameter', 'collections.filters', 'collections.filterParameter', 'collections.subFilters', 'collections.subFilterParameter', 'reports.filters', 'reports.filterParameter', 'channels.filters', 'channels.filterParameter'],
		}),
		NgxsModule.forRoot(CORE_STATES, {
			developmentMode: !environment.production,
		}), //
		NgxsDataPluginModule.forRoot([NGXS_DATA_STORAGE_PLUGIN]),
		NgxsReduxDevtoolsPluginModule.forRoot({
			disabled: environment.production,
		}),
	],
})
export class PortalsStoreModule {}
