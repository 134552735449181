import { StrapiMedia } from '@portals/api';
import { ReachEvent } from '../reach-event';

export type ReachLab = {
	id: number;
	uuid: string;
	code: string;
	description: string;
	email: string;
	events: Array<ReachEvent>;
	name: string;
	url: string;
	initials: string;
	banner: StrapiMedia;
	active: boolean;
};

export type CreateReachLabPayload = {
	name: string;
	code: string;
	is_active: boolean;
};
