import {Injectable} from '@angular/core';
import {DataProvider, StrapiService} from "../../core";
import {Link, LINK_DOMAINS, LINK_RESPONSE, LINK_TYPES} from "./link.model";
import {environment} from "@portals/environments";
import {DeviceDetectorService} from "ngx-device-detector";
import {HttpClient} from "@angular/common/http";
import {InterceptorSkipHeader} from "@portals/core";
import {mergeMap} from "rxjs";
import * as CryptoJS from 'crypto-js';

interface AndroidVersionToApiLevelMap {
	[version: string]: string;
}

const androidVersionToApiLevel: AndroidVersionToApiLevelMap = {
	"13": "33",
	"12L": "32",
	"12": "31",
	"11": "30",
	"10": "29",
	"9": "28", // Pie
	"8.1": "27", // Oreo
	"8.0": "26", // Oreo
	"7.1": "25", // Nougat
	"7.0": "24", // Nougat
	"6.0": "23", // Marshmallow
	"5.1": "22", // Lollipop
	"5.0": "21", // Lollipop
	"4.4": "19", // KitKat
	"4.3": "18", // Jelly Bean
	"4.2": "17", // Jelly Bean
	"4.1": "16", // Jelly Bean
	"4.0.3": "15", // Ice Cream Sandwich
	"4.0": "14", // Ice Cream Sandwich
	"3.2": "13", // Honeycomb
	"3.1": "12", // Honeycomb
	"3.0": "11", // Honeycomb
	"2.3": "10", // Gingerbread
	"2.2": "8",  // Froyo
	// Add more mappings as needed
};


@Injectable({
	providedIn: 'root'
})
export class LinkService extends StrapiService<Link> {
	protected override model: string = 'links';

	constructor(dataProvider: DataProvider, public deviceService: DeviceDetectorService, public http: HttpClient) {
		super(dataProvider);
	}

	getLink(domain: string, endpoint: string) {
		return this.dataProvider.get<LINK_RESPONSE>(`links/getType?domain=${domain}&endpoint=${endpoint}`)
	}

	getIpAddress() {
		return this.http.get<{
			ip: string
		}>('https://api.ipify.org?format=json', {headers: {[InterceptorSkipHeader]: 'true'}})
	}

	parseOSVersion(userAgent: string): string {
		let osVersion: string = "Unknown OS";

		// Check for Android
		const androidMatch = userAgent.match(/Android\s+([\d.]+)/);
		if (androidMatch && androidMatch.length > 1) {
			const version = androidMatch[1].split('.').slice(0, 2).join('.'); // Simplify version to match keys
			osVersion = `Android ${androidVersionToApiLevel[version]}`
		}

		// Check for iOS
		const iosMatch = userAgent.match(/OS (\d+)_?(\d+)?_?(\d+)? like Mac OS X/);
		if (iosMatch && iosMatch.length > 1) {
			const majorVersion = iosMatch[1];
			const minorVersion = iosMatch[2] ? `.${iosMatch[2]}` : '';
			const patchVersion = iosMatch[3] ? `.${iosMatch[3]}` : '';
			osVersion = `iOS ${majorVersion}${minorVersion}${patchVersion}`;
			return osVersion;
		}

		return osVersion;
	}

	installJotto(channelUUID: string | undefined, deepLink: string | undefined, type?: LINK_TYPES) {
		if (channelUUID && deepLink && !this.deviceService.isDesktop()) {
			if (this.isDomainInEnum(deepLink as string)) {
				window.open(`${deepLink}?redirected=true&type=${type}`, '_self')
			} else {
				window.open(deepLink, '_self');
			}
		} else if (this.deviceService.isDesktop()) {
			window.open(`${environment.portalUrl}/web/share/${channelUUID}`, '_blank');
		}
	}

	isDomainInEnum(url: string): boolean {
		let hostname;
		try {
			const preparedUrl = url.startsWith('http') ? url : `https://${url}`;
			hostname = ("https://" + new URL(preparedUrl).hostname + "/") as LINK_DOMAINS;
		} catch (error) {
			return false;
		}

		return Object.values(LINK_DOMAINS).includes(hostname);
	}

	sendToAppStore(type: LINK_TYPES, deepLink: string) {
		this.getIpAddress().pipe(
			mergeMap(data => {
				const deviceInfo = this.deviceService.getDeviceInfo()
				const OSInfo = this.parseOSVersion(deviceInfo.userAgent)
				const message = String(data.ip + deviceInfo.os + OSInfo + (OSInfo.includes('Android') ? 'Android' : deviceInfo.device))
				const hashedString = CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
				window.open(deviceInfo.os == 'iOS' ? 'https://apps.apple.com/us/app/jotto-video/id1446288804' : this.deviceService.isDesktop() ? 'https://play.google.com/store/apps/details?id=us.quadrant2.jotto&hl=en_US&gl=US&pli=1' : `intent://${deepLink.replace(/^https:\/\//, "")}#Intent;scheme=https;package=us.quadrant2.jotto;S.browser_fallback_url=https://play.google.com/store/apps/details?id=us.quadrant2.jotto&hl=en_US&gl=US&pli=1;end`, '_self');
				return this.dataProvider.post<LINK_RESPONSE>(`links/setDeepLink`, {
					hash: hashedString,
					type,
					deepLink
				})
			})
		).subscribe()
	}
}
