import { Injectable } from '@angular/core';
import { DataProvider, StrapiService } from '../../core';
import { Collectible } from './collectible.model';

import { map } from 'rxjs/operators';

import { MapData } from '@portals/api';
@Injectable()
export class CollectibleService extends StrapiService<Collectible> {
	protected override model: string = 'collectibles';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}

	postTrustedContacts(contacts: any) {
		return this.dataProvider.post<any>('trustedcontacts', contacts);
	}

	incidentViewer(uuid: any) {
		return this.dataProvider.get<Collectible>(`incident-viewer/${uuid}`);
	}

	momentQuery(notificationID: number) {
		return this.dataProvider.get<any>(`/collectibles/moments/momentsQuery?id=${notificationID}`);
	}


	putTranscriptionText(uuid: string, data: any) {
		return this.dataProvider.put<any>(`/collectibles/${uuid}/transcription`, data);
	}
	getFullTranscription(uuid: string) {
		return this.dataProvider.get<any>(`/collectibles/${uuid}/transcription`);
	}
	getMapsData() {
		return this.dataProvider.get<MapData[]>(`/collectibles/maps`);
	}
}
