import { animate, state, style, transition, trigger } from '@angular/animations';

// FADE OUT
export const fadeOut = trigger('fadeOut', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':leave', animate(100, style({ opacity: 0 })))]);

export const fadeOut1 = trigger('fadeOut1', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':leave', animate(1000, style({ opacity: 0 })))]);

export const fadeOut2 = trigger('fadeOut2', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':leave', animate(1500, style({ opacity: 0 })))]);

export const fadeOut3 = trigger('fadeOut3', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':leave', animate(2000, style({ opacity: 0 })))]);

// FADE OUT (w/ DELAY)
export const fadeOutDelay = trigger('fadeOutDelay', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':leave', [animate(500, style({ opacity: 0 })), animate(500, style('*'))])]);

export const fadeOutDelay1 = trigger('fadeOutDelay1', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':leave', [animate(1000, style({ opacity: 0 })), animate(1000, style('*'))])]);

export const fadeOutDelay2 = trigger('fadeOutDelay2', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':leave', [animate(1500, style({ opacity: 0 })), animate(1500, style('*'))])]);

export const fadeOutDelay3 = trigger('fadeOutDelay3', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':leave', [animate(2000, style({ opacity: 0 })), animate(2000, style('*'))])]);
