import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { ReachEventCompany, ReachEventCompanyService } from '@portals/api';

import { finalize, mergeMap, tap } from 'rxjs/operators';

interface StateModel {
	isLoading: boolean;
	data: Array<ReachEventCompany>;
	selected: ReachEventCompany;
}

@StateRepository()
@State<StateModel>({
	name: 'companies',
})
@Injectable()
export class ReachEventCompaniesState extends NgxsDataRepository<StateModel> {
	@Computed()
	get isLoading() {
		return this.snapshot.isLoading;
	}

	@Computed()
	get hasData() {
		return !!this.snapshot.data;
	}

	@Computed()
	get data() {
		return this.snapshot.data;
	}

	@Computed()
	get item() {
		return this.snapshot.selected;
	}

	constructor(private reachEventCompanyService: ReachEventCompanyService) {
		super();
	}

	load() {
		this.patchState({ isLoading: true });

		return this.reachEventCompanyService.find({ params: { sort: 'createdAt:desc' } }).pipe(
			tap(({ data }) => {
				this.patchState({ data });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	findOne(id: number) {
		return this.reachEventCompanyService.findOne(id);
	}

	create(payload: FormData) {
		this.patchState({ isLoading: true });

		return this.reachEventCompanyService.create(payload).pipe(mergeMap(() => this.load()));
	}

	update(id: number, formData: FormData) {
		this.patchState({ isLoading: true });

		return this.reachEventCompanyService.update(id, formData).pipe(mergeMap(() => this.load()));
	}

	delete(id: number) {
		this.patchState({ isLoading: true });

		return this.reachEventCompanyService.delete(id).pipe(mergeMap(() => this.load()));
	}
}
