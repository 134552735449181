import { User } from '../user';

// Login
export type LoginPayload = {
	identifier: string;
	password: string;
};

export type LoginResponse = {
	jwt?: string;
	user: User;
};

// Register
export type RegisterPayload = {
	first_name?: string;
	last_name?: string;
	email?: string;
	domain?: string;
	app?: string;
	logo?: string;
	primaryHex?: string;
	secondaryHex?: string;
	replyTo?: string;
	password?: string;
};

// Confirm User
export type ConfirmationRequestPayload = {
	first_name?: string;
	last_name?: string;
	email?: string;
	domain?: string;
	app?: string;
	logo?: string;
	primaryHex?: string;
	secondaryHex?: string;
	replyTo?: string;
};

// Reset Password
export type ConfirmationPayload = {
	email: string;
	token: string;
};

export type ConfirmationResponsePayload = {
	jwt: string;
	user: User;
};

// Forgot Password
export type ForgotPasswordPayload = {
	email?: string;
	domain?: string;
	app?: string;
	logo?: string;
	primaryHex?: string;
	secondaryHex?: string;
	replyTo?: string;
};

export type ForgotPasswordResponse = {
	statusCode: number;
	response: string;
};

// Reset Password
export type ResetPasswordPayload = {
	email: string;
	token: string;
	password: string;
	password_confirmation: string;
};

export type RegisterResponse = LoginResponse;
export type ResetPasswordResponse = LoginResponse;
