export type NarProject = {
	title: string;
	email: string;
	uuid: string;
	// published_at: string;
	// created_at: string;
	// updated_at: string;
	publishedAt: Date;
	createdAt: Date;
	updatedAt: Date;
};

export type NarProjectConnections = {
	project: NarProject;
	connection_email: string;
	// published_at: string;
	// created_at: string;
	// updated_at: string;
	publishedAt: Date;
	createdAt: Date;
	updatedAt: Date;
};
