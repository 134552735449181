import { Injectable } from '@angular/core';
import { DataProvider, RequestOptions } from './data.provider';
import { debounce, debounceTime, distinctUntilChanged, interval, Observable, Subject, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataResponse, flattenAttributes, parseResponse, RawDataResponse } from '../services';
import { buildQueryParameters } from '@portals/api';

@Injectable()
export class StrapiService<T> {
	protected model!: string;
	apiCall = new Subject();

	constructor(protected dataProvider: DataProvider) {}

	find(options?: RequestOptions) {
		this.validateModel();
		const { params = {}, ...otherProp } = options || {};
		const query: string = buildQueryParameters(params);

		return this.dataProvider.get<RawDataResponse>(`${this.model}?${query}`, otherProp).pipe(map((response) => parseResponse(response)));
	}

	customGetPath(options: any) {
		this.validateModel();
		return this.dataProvider.get<any>(`${this.model}/${options.path}`);
	}

	findOne(id: number | string, options?: RequestOptions) {
		this.validateModel();
		const { params = {}, ...otherProp } = options || {};
		const query: string = buildQueryParameters(params);

		return this.dataProvider.get<T>(`${this.model}/${id}?${query}`, otherProp).pipe(map(flattenAttributes));
	}

	findOneById(id: number, options?: RequestOptions) {
		this.validateModel();
		const { params = {}, ...otherProp } = options || {};
		const query: string = buildQueryParameters(params);

		return this.dataProvider.get<T>(`${this.model}?id=${id}&${query}`, otherProp).pipe(map(flattenAttributes));
	}

	findOneByUUID(uuid: string, options?: RequestOptions) {
		this.validateModel();
		const { params = {}, ...otherProp } = options || {};
		const query: string = buildQueryParameters(params);

		return this.dataProvider.get<T>(`${this.model}?uuid=${uuid}&${query}`, otherProp).pipe(map(flattenAttributes));
	}

	count(options?: RequestOptions) {
		this.validateModel();
		const { params = {}, ...otherProp } = options || {};
		const query: string = buildQueryParameters(params);

		return this.dataProvider.get<number>(`${this.model}/count?${query}`, otherProp);
	}

	create(payload: Omit<T, 'id'> | FormData, options?: RequestOptions) {
		this.validateModel();
		return this.dataProvider.post<T>(this.model, payload, options);
	}

	connect(payload: any) {
		this.validateModel();
		return this.dataProvider.post<T>(this.model, payload);
	}

	update<U>(id: number | string, payload: Partial<T> | { data: Partial<T> } | FormData) {
		this.validateModel();
		return this.dataProvider.put<U>(`${this.model}/${id}`, payload);
	}

	delete(id: number) {
		this.validateModel();
		return this.dataProvider.delete(`${this.model}/${id}`);
	}

	private validateModel() {
		if (!this.model) {
			throw new Error("Please set the 'model' param to an existing collection model from your Strapi API");
		}
	}

	get(url: string, options?: RequestOptions) {
		const { params = {}, ...otherProp } = options || {};
		const query: string = buildQueryParameters(params);

		return this.dataProvider.get<T[]>(`${url}?${query}`, otherProp);
	}
}
