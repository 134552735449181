const qs = require('qs');

import { Filter, FilterDataType } from '.';
import { environment } from '@portals/environments';

function buildObjectFromArray(
	keys: string[],
	conditions: string | string[],
	value: any | any[]
): object {
	let obj: { [key: string]: any } = {};
	let current = obj;
	for (let i = 0; i < keys.length - 1; i++) {
		current[keys[i]] = {};
		current = current[keys[i]];
	}
	current[keys[keys.length - 1]] = {};
	if (typeof conditions == "string") {
		current[keys[keys.length - 1]][conditions] = value;
	} else {
		conditions.forEach((condition, index) => {
			current[keys[keys.length - 1]][condition] = value[index];
		})
	}
	return obj;
}

export const getFilterParameters = (rawFilters: Filter[]) => {
	let filters: any = {};
	const filterMapping: any = {
		Is: '$eq',
		'Is not': '$ne',
		Contains: '$containsi',
		'Does not contain': '$notContainsi',
		Equals: '$eq',
		'Less than': '$lt',
		'Greater than': '$gt',
		'Does not equal': '$ne',
		'Less than or equal to': '$lte',
		'Greater than or equal to': '$gte',
		'Is before': '$lte',
		'Is after': '$gte',
	};

	// assemble filter
	rawFilters.forEach((filter: any, index) => {
		const { field: filterField = '', condition, value, rangeValue, parameter, collectionType } = filter || {};
		let keySuffix = filterMapping[condition];
		let keys = !parameter?.nestedKey ? parameter.key : `${parameter.key}.${parameter.nestedKey}`;
		keys = keys.split('.').map((i: string) => `${i}`);
		const isObject = Object.prototype.toString.call(rangeValue[0]) === '[object Object]';
		const parsedRangeValue = isObject ? rangeValue.map((rVal: any) => rVal.value) : rangeValue;
		const specialKeyFilters = ['Curbed'];

		if (!specialKeyFilters.includes(parameter.text)) {
			if (parsedRangeValue.length) {
				if (['Between', 'Is between'].includes(condition)) {
					let lowerValue,greaterValue;
					if (parsedRangeValue[0] > parsedRangeValue[1]) {
						lowerValue = parsedRangeValue[1]
						greaterValue = parsedRangeValue[0]
					} else {
						lowerValue = parsedRangeValue[0]
						greaterValue = parsedRangeValue[1]
					}

					filters = {
						...filters,
						...buildObjectFromArray(
							keys,
							[filterMapping['Is after'], filterMapping['Is before']],
							[lowerValue, greaterValue]
						)
					};
				} else if (filterField === 'multiselect') {
					if (condition === 'Is') {
						filters = {
							...filters,
							...buildObjectFromArray(keys, '$in', parsedRangeValue),
						};
					} else if (condition === 'Is not') {
						filters = {
							...filters,
							...buildObjectFromArray(keys, '$notIn', parsedRangeValue),
						};
					} else {
						filters = {
							...filters,
							...buildObjectFromArray(keys, keySuffix, parsedRangeValue),
						};
					}
				} else if (filterField === 'location') {
					const locationKeySuffix = condition === 'Is within' ? '$in' : '$notIn';
					filters = {
						...filters,
						...buildObjectFromArray(['latitude'], locationKeySuffix, parsedRangeValue[0]),
						...buildObjectFromArray(['longitude'], locationKeySuffix, parsedRangeValue[1]),
					};
				} else {
					filters = {
						...filters,
						...buildObjectFromArray(keys, keySuffix, parsedRangeValue),
					};
				}
			} else {
				filters = {
					...filters,
					...buildObjectFromArray(keys, keySuffix, value),
				};
			}
		} else {
			if (parameter.text === 'Curbed') {
				const mutedUsers = JSON.parse(sessionStorage.getItem(`${collectionType}.mutedUsers`) || '[]');
				let curbedKeySuffix: string = '';

				if (condition === 'Is') {
					curbedKeySuffix = value ? '$in' : '$notIn';
				} else if (condition === 'Is not') {
					curbedKeySuffix = value ? '$notIn' : '$in';
				}

				filters = {
					...filters,
					...buildObjectFromArray(keys, curbedKeySuffix, mutedUsers),
				};
			}
		}
	});

	return { filters };
};

export const getRoleOptionsByUserRole = () => {
	const userData: any = JSON.parse(localStorage.getItem('@ngxs.store.user') || '{}');
	const { role: { name } = '' } = userData?.data?.data || {};
	const role: string = (name || 'public').toLowerCase();
	let selectedRole = [];
	const roleGroupOptions = {
		public: [{ value: 'Public', label: 'Public' }],
		'intake viewer': [
			{ value: 'Public', label: 'Public' },
			{ value: 'Intake Viewer', label: 'Intake Viewer' },
		],
		'affiliate admin': [
			{ value: 'Public', label: 'Public' },
			{ value: 'Intake Viewer', label: 'Intake Viewer' },
			{ value: 'Affiliate Admin', label: 'Portal Admin' },
		],
		'aclu admin': [
			{ value: 'Public', label: 'Public' },
			{ value: 'Intake Viewer', label: 'Intake Viewer' },
			{ value: 'ACLU Admin', label: 'Platform Admin' },
		],
		'super admin': [
			{ value: 'Public', label: 'Public' },
			{ value: 'Intake Viewer', label: 'Intake Viewer' },
			{ value: 'ACLU Admin', label: 'Platform Admin' },
			{ value: 'Affiliate Admin', label: 'Portal Admin' },
			{ value: 'Super Admin', label: 'Super Admin' },
		],
	};

	switch (role) {
		case 'public':
			selectedRole = roleGroupOptions['public'];
			break;
		case 'intake viewer':
			selectedRole = roleGroupOptions['intake viewer'];
			break;
		case 'affiliate admin':
			selectedRole = roleGroupOptions['affiliate admin'];
			break;
		case 'aclu admin':
			selectedRole = roleGroupOptions['aclu admin'];
			break;
		case 'super admin':
			selectedRole = roleGroupOptions['super admin'];
			break;
		default:
			selectedRole = roleGroupOptions['public'];
			break;
	}

	return selectedRole;
};

export const isSuperAdmin = (): boolean => {
	const userData: any = JSON.parse(localStorage.getItem('@ngxs.store.user') || '{}');
	const { role: { name } = '' } = userData?.data?.data || {};
	const role: string = (name || 'public').toLowerCase();

	return role === 'super admin';
};

export const sortFilterDataTypeAlphabetically = (filterDataTypes: FilterDataType[]): FilterDataType[] => {
	return filterDataTypes.sort((a, b) => a.text.localeCompare(b.text));
};

export const buildQueryParameters = (parameters: any): string => {
	const query = qs.stringify(parameters, {
		encodeValuesOnly: true, // prettify URL
	});

	return query;
};
