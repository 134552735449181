export class Stack<T> {
	private items: T[] = [];

	// Method to add an item to the stack
	push(item: T) {
		this.items.push(item);
	}

	// Method to remove and return the last item from the stack
	pop(): T | undefined {
		return this.items.pop();
	}

	// Method to view the last item without removing it
	peek(): T | undefined {
		return this.items[this.items.length - 1];
	}

	// Method to check if the stack is empty
	isEmpty(): boolean {
		return this.items.length === 0;
	}

	// Method to get the size of the stack
	size(): number {
		return this.items.length;
	}

	// Method to clear the stack
	clear() {
		this.items = [];
	}
}
