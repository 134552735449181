import { Injectable } from '@angular/core';
import { Device, RegisterDevicePayload } from '@portals/api';
import { DataProvider, StrapiService } from '../../core';

@Injectable()
export class DeviceService extends StrapiService<Device> {
	protected override model: string = 'devices';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}

	registerDevice(payload: RegisterDevicePayload) {
		return this.dataProvider.post<Device>(
			`${this.model}`,
			{
				data: payload,
			},
			{ skipInterceptor: true }
		);
	}
}
