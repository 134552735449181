export interface Environment {
	public: boolean;
	streamname?: string;
	defaultChannel?: string;
	terms: string;
	policy: string;
	production: boolean;
	app: string;
	s3Bucket?: string;
	logo: string;
	baseUrl: string;
	primaryHex: string;
	secondaryHex: string;
	tutorial: boolean;
	support: string;
	s3ThumbnailBucket?: string;
	mapBoxToken?: string;
	multiProject?: boolean;
	firebase?: any;
	version?: string;
	portalUrl?: string;
	transcriptionApiKey?: string;
	defaultDynamicLink?: string;
}

export interface JottoWebEnvironments {
	defaultNotification : number;
	specialRoutes : boolean
}

export interface CombinedEnvironment extends Environment, JottoWebEnvironments {}
