import { Injectable } from '@angular/core';
import { Currency } from '@portals/api';
import { DataProvider, StrapiService } from '../../core';
import { TobaccoItem } from './tobacco-item.model';

@Injectable()
export class TobaccoItemService extends StrapiService<TobaccoItem> {
	protected override model = 'tobacco-items';

	constructor(private dataService: DataProvider) {
		super(dataService);
	}

	getItems() {
		return this.dataService.get<TobaccoItem[]>(this.model, {
			params: { sort: 'createdAt:desc' },
		});
	}

	getCurrencies() {
		return this.dataService.get<Currency[]>('currencies');
	}

	getItem(location: number) {
		return this.dataService.get<TobaccoItem>(`${this.model}/${location}`);
	}

	putItem(location: any, data: any) {
		return this.dataService.put<any>(`${this.model}/${location}`, data);
	}
}
