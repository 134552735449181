import { ArticlesState } from './articles.state';
import { ChannelsState } from './channels.state';
import { CollectiblesState } from './collectibles.state';
import { CollectionsState } from './collections.state';
import { CommentsState } from './comments.state';
import { FollowsState } from './follow.state';
import { LanguagesState } from './lang.state';
import { MutesState } from './mutes.state';
import { NotificationsState } from './notifications.state';
import { ProjectsState } from './projects.state';
import { ReachEventCompanyLeadsState } from './reach-event-companies-leads.state';
import { ReachEventCompaniesState } from './reach-event-companies.state';
import { ReachEventsState } from './reach-events.state';
import { ReachLabsState } from './reach-labs.state';
import { ReportsState } from './reports.state';
import { RolesState } from './roles.state';
import { TagsState } from './tags.state';
import { AuthState } from './auth.state';
import { UsersState } from './users.state';
import { RequestsState } from './requests.state';

export const CORE_STATES = [AuthState, ArticlesState, ChannelsState, NotificationsState, ProjectsState, RolesState, UsersState, TagsState, CollectionsState, CollectiblesState, CommentsState, ReachEventsState, ReachLabsState, ReachEventCompaniesState, ReachEventCompanyLeadsState, MutesState, FollowsState, ReportsState, LanguagesState, RequestsState];

export * from './auth.state';
export * from './channels.state';
export * from './notifications.state';
export * from './projects.state';
export * from './roles.state';
export * from './users.state';
export * from './tags.state';
export * from './collections.state';
export * from './collectibles.state';
export * from './comments.state';
export * from './reach-events.state';
export * from './reach-labs.state';
export * from './reach-event-companies.state';
export * from './reach-event-companies-leads.state';
export * from './articles.state';
export * from './mutes.state';
export * from './follow.state';
export * from './server-datatable-service.interface';
export * from './reports.state';
export * from './lang.state';
export * from './requests.state';
