import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { HotToastModule } from '@ngneat/hot-toast';
import { PortalsCoreModule } from '@portals/core';

@NgModule({
	declarations: [AppComponent],
	imports: [
		PortalsCoreModule.forRoot([]),
		HotToastModule.forRoot({
			position: 'bottom-left',
		}),
		RouterModule.forRoot([
			{
				path: '',
				loadChildren: () => import('@jotto/let-us-know').then((m) => m.LetUsKnowModule),
			},
			{
				path: '**',
				redirectTo: '/404',
				pathMatch: 'full',
			},
		]),
	],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
