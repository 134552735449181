import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { error } from 'console';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';

export enum ErrorSeverity {
	INFO = 'INFO',
	WARNING = 'WARNING',
	ERROR = 'ERROR',
	FATAL = 'FATAL',
}

export interface BackendError {
	message: string;
	severity: ErrorSeverity;
	code: string;
}

export const genericRetryStrategy =
	({
		maxRetryAttempts = 3,
		scalingDuration = 1000,
		excludedStatusCodes = [],
	}: {
		maxRetryAttempts?: number;
		scalingDuration?: number;
		excludedStatusCodes?: number[];
	} = {}) =>
	(attempts: Observable<any>) => {
		return attempts.pipe(
			mergeMap((error, i) => {
				const retryAttempt = i + 1;
				if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => e === error?.status)) {
					return throwError(error);
				}
				return timer(retryAttempt * scalingDuration);
			})
		);
	};

@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {
	error!: BackendError;

	constructor(private toast: HotToastService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			retryWhen(
				genericRetryStrategy({
					excludedStatusCodes: [400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417, 418, 421, 422, 423, 424, 426, 428, 429, 431, 451, 500],
				})
			),
			catchError((err) => {
				if (err instanceof ErrorEvent) {
					this.error = this.handleUnknownError();
				} else {
					this.error = this.handleBackendError(err);
				}

				if (err?.status !== 500) {
					typeof this.error?.message === 'string'
						? this.toast.error(this.error?.message, {
								autoClose: true,
								dismissible: false,
								duration: 10000,
								style: {
									border: '4px solid #af3131',
									padding: '16px',
									margin: '20px 20px',
									color: 'white',
									background: '#af3131',
								},
								iconTheme: {
									primary: 'white',
									secondary: '#af3131',
								},
						  })
						: null;
				}

				return throwError(this.error);
			})
		);
	}

	private handleUnknownError(): BackendError {
		return {
			message: 'Unknown error!',
			severity: ErrorSeverity.FATAL,
			code: 'UNKNOWN_ERROR',
		};
	}

	private handleBackendError(err: any): BackendError {
		return {
			message: err.error?.message ? err.error?.message : err.error?.error.message,
			severity: ErrorSeverity.ERROR,
			code: err.error?.identifierCode ? err.error.identifierCode : err.status ? err.status : err.error?.status ? err.error?.status : 'BACKEND_ERROR',
		};
	}
}
