import {Channel, Notification} from "@portals/api";

export enum SCREENS {
	WELCOME_SCREEN,
	RESPONSE_SCREEN,
	UPLOADING_SCREEN,
	CHANNEL_FEED_SCREEN
}

export enum RESPONSE_TYPES {
	VIDEO = "VIDEO",
	TEXT = "TEXT",
	AUDIO = "AUDIO"
}

export type PARAMETERS = {
	channel?: Channel,
	notification?: Notification,
	notificationType?: RESPONSE_TYPES
}
