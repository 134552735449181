import {Environment, JottoWebEnvironments} from '@portals/environments';

export const environment: Environment & JottoWebEnvironments = {
	production: true,
	streamname: 'jotto',
	specialRoutes: false,
	app: 'Jotto',
	baseUrl: 'https://api.jotto.ai/v2/',
	defaultChannel: '2b878fba-a0fc-4845-9b9b-e0c6de090006',
	defaultNotification: 1177,
	primaryHex: '#491176',
	secondaryHex: '#fbd354',
	logo: 'https://g3-jotto-uploads.s3.amazonaws.com/jotto_accent_cef194081a.png',
	public: true,
	tutorial: true,
	multiProject: true,
	portalUrl: 'https://console.jotto.ai',
	terms: 'https://jotto.me/terms',
	policy: 'https://jotto.me/privacy',
	support: 'developer@quadrant2.tech',
	s3ThumbnailBucket: 'https://g3-jotto-video-thumbnails.s3.amazonaws.com/',
	mapBoxToken: 'sk.eyJ1IjoiYWxleG9ub3pvciIsImEiOiJja2YydTV6ZGMwMXd6MnpxcW5yb3pkYm84In0.mIW08fd6Mb1VZ9nkYN5h9Q',
	firebase: {
		apiKey: 'AIzaSyAMT2P0L8ccFwmNKuZHtU2Yo5ErJYnXTdQ',
		authDomain: 'jotto-f8a17.firebaseapp.com',
		databaseURL: 'https://jotto-f8a17.firebaseio.com',
		projectId: 'jotto-f8a17',
		storageBucket: 'jotto-f8a17.appspot.com',
		messagingSenderId: '924869180612',
		appId: '1:924869180612:web:f64d3a43cfe7ae8eb01d91',
		measurementId: 'G-7FL5WS4207',
	},
	version: '1.0.0',
};
