import { CollectionService } from './collection';
import { AppService } from './app';
import { AuthService } from './auth';
import { ChannelService } from './channel';
import { CollectibleService } from './collectible';
import { NarProjectService } from './nar-project';
import { JottoNotificationService } from './notification';
import { JottoProjectService } from './project';
import { ReportLocationService } from './report-location';
import { TobaccoItemService } from './tobacco-item';
import { UserService } from './user';
import { RolesService } from './role';
import { TagService } from './tag';
import { CommentService } from './comment';
import { ReachEventService } from './reach-event';
import { ReportService } from './report';
import { ReachLabService } from './reach-lab';
import { TimeZoneService } from './timezone';
import { ReachEventCompanyService } from './reach-event-company';
import { ReachEventCompanyLeadService } from './company-lead';
import { ArticleService } from './article';
import { MuteService } from './mute';
import { DistinctService } from './distinct';
import { FollowService } from './follow';
import { LanguageService } from './lang/lang.service';
import { MapBoxService } from './mapbox';
import { RequestsService } from './request';
import { DeviceService } from './device';
import { AWSUploadService } from './aws';
import {LinkService} from "./link";

export const API_PROVIDERS = [
	AppService,
	AuthService,
	ArticleService,
	ReportService,
	UserService,
	NarProjectService,
	TobaccoItemService,
	ReportLocationService,
	JottoNotificationService,
	ChannelService,
	FollowService,
	JottoProjectService,
	CollectibleService,
	CollectionService,
	RolesService,
	MuteService,
	TagService,
	CommentService,
	ReachEventService,
	ReachLabService,
	TimeZoneService,
	ReachEventCompanyService,
	ReachEventCompanyLeadService,
	DistinctService,
	LanguageService,
	RequestsService,
	MapBoxService,
	DeviceService,
	AWSUploadService,
	LinkService
];

// Export API services individually here.
export * from './app';
export * from './auth';
export * from './user';
export * from './reach-event';
export * from './reach-lab';
export * from './nar-project';
export * from './tobacco-item';
export * from './report-location';
export * from './notification';
export * from './strapi-media';
export * from './channel';
export * from './project';
export * from './comment';
export * from './form';
export * from './form-data';
export * from './form-field';
export * from './collectible';
export * from './report';
export * from './tag';
export * from './collection';
export * from './filter';
export * from './role';
export * from './comment';
export * from './timezone';
export * from './reach-event-company';
export * from './company-lead';
export * from './article';
export * from './mute';
export * from './distinct';
export * from './follow';
export * from './lang';
export * from './mapbox';
export * from './api.utils';
export * from './request';
export * from './device';
export * from './aws';
export * from './link';
