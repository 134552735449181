import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PARAMETERS, SCREENS, Stack} from "@portals/utils";

@Injectable({
	providedIn: 'root',
})
export class ResponseService {
	public navigationStack: Stack<{ screen: SCREENS, parameters: PARAMETERS }> = new Stack<{ screen: SCREENS; parameters: PARAMETERS }>();
	private CURRENT_PAGE : { screen: SCREENS, parameters: PARAMETERS } | undefined

	constructor() {
	}

	get currentPage() {
		return this.CURRENT_PAGE
	}

	set currentPage(payload) {
		this.CURRENT_PAGE = payload
	}

	navigateTo(screen : SCREENS, parameters : PARAMETERS) {
		if (this.CURRENT_PAGE) this.navigationStack.push(this.CURRENT_PAGE)
		this.CURRENT_PAGE = {screen, parameters}
	}

	navigateBack() {
		this.CURRENT_PAGE = this.navigationStack.pop()
	}
}
