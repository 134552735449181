// import { ComponentType } from '@angular/cdk/portal';
// import { Injectable } from '@angular/core';
// import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';
// import { Router } from '@angular/router';
// import { Subject } from 'rxjs';
// import { PortalService } from './portal.service';
//
// @Injectable()
// export class DrawerService<T = unknown> extends PortalService {
// 	/** The panel. */
// 	private drawer?: MatSidenav;
// 	public context?: T;
// 	public results = new Subject();
//
// 	/**
// 	 * Sets and instance of the drawer that we need to interact with
// 	 * @param drawer Instance of MatSidenav
// 	 * @see DrawerService#connectDrawer
// 	 */
//
// 	constructor(private router: Router) {
// 		super();
// 	}
//
// 	connectDrawer(drawer: MatSidenav): void {
// 		this.drawer = drawer;
// 	}
//
// 	/** Opens the drawer with optionally a content to be set. */
// 	async open(
// 		componentRef: ComponentType<any>,
// 		context?: T
// 	): Promise<MatDrawerToggleResult | undefined> {
// 		if (!this.drawer) {
// 			return;
// 		}
//
// 		this.updatePortalContent(componentRef);
// 		this.context = context;
//
// 		return this.drawer.open();
// 	}
//
// 	/** Toggles the panel. */
// 	async toggle(): Promise<MatDrawerToggleResult | undefined> {
// 		return this.drawer?.toggle();
// 	}
//
// 	/** Closes the panel. */
// 	async close(reload?: boolean): Promise<MatDrawerToggleResult | undefined> {
// 		if (!this.drawer) {
// 			return;
// 		}
//
// 		if (reload) {
// 			super.reloadComponent(this.router);
// 		}
//
// 		this.clearPortalContent();
// 		this.context = undefined;
//
// 		return this.drawer.close();
// 	}
// }

import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { PortalService } from './portal.service';

@Injectable()
export class DrawerService<T = unknown> extends PortalService {
	/** The panel. */
	private drawer?: MatSidenav;
	public context?: T;
	public results = new Subject<any>();

	constructor(private router: Router) {
		super();
	}

	connectDrawer(drawer: MatSidenav): void {
		this.drawer = drawer;
	}

	/** Opens the drawer with optionally a content to be set. */
	async open(componentRef: ComponentType<any>, context?: T): Promise<MatDrawerToggleResult | undefined> {
		if (!this.drawer) {
			return;
		}

		this.updatePortalContent(componentRef);
		// @ts-ignore
		this.context = context;

		return this.drawer.open();
	}

	/** Opens a nested drawer with a content component and waits for data from it. */
	/** Opens a nested drawer with a content component and waits for data from it. */
	async openNestedComponent(componentRef: ComponentType<any>, context?: T): Promise<T | undefined> {
		if (!this.drawer) {
			return;
		}

		// Open the nested drawer and wait for it to close
		await this.open(componentRef, context); // Open the nested drawer

		// Wait for the nested drawer to emit data or be closed
		return new Promise<T | undefined>((resolve) => {
			const subscription = this.results.subscribe((data) => {
				resolve(data); // Resolve the promise with the data emitted by the nested drawer
				subscription.unsubscribe(); // Unsubscribe to prevent memory leaks
			});

			// Listen to the openedChange event of the drawer
			const drawerOpenedChangeSubscription = this.drawer!.openedChange.subscribe((opened) => {
				if (!opened) {
					// The nested drawer is closed, emit the result and clean up
					this.context = undefined; // Reset context after the nested drawer is closed
					drawerOpenedChangeSubscription.unsubscribe(); // Unsubscribe from the openedChange event
				}
			});
		});
	}

	/** Toggles the panel. */
	async toggle(): Promise<MatDrawerToggleResult | undefined> {
		return this.drawer?.toggle();
	}

	/** Closes the panel. */
	async close(reload?: boolean): Promise<MatDrawerToggleResult | undefined> {
		if (!this.drawer) {
			return;
		}

		if (reload) {
			super.reloadComponent(this.router);
		}

		this.clearPortalContent();
		this.context = undefined;
		return this.drawer.close();
	}
}
