import { Filter, isSuperAdmin, FilterDataType, getRoleOptionsByUserRole, sortFilterDataTypeAlphabetically } from '.';
import { environment } from '@portals/environments';

interface ObjFilterDataType {
	[key: string]: FilterDataType;
}

export const COMMON_FILTER_DATA_TYPES: ObjFilterDataType = {
	createdAt: {
		key: 'createdAt',
		text: 'Recorded Date',
		field: 'date',
		condition: 'date',
	},
	updatedAt: {
		key: 'updatedAt',
		text: 'Last Updated',
		field: 'date',
		condition: 'date',
	},
	title: {
		key: 'title',
		text: 'Title',
		field: 'text',
		condition: 'text',
	},
	'channel.name': {
		key: 'channel.name',
		text: environment.app === 'Mobile Justice' ? 'Affiliate' : 'Channel',
		field: 'multiselect',
		condition: 'text',
		entityName: 'channel',
		fieldName: 'name',
		options: [],
	},
};

/*
 *
 * NOTIFICATIONS STATE FILTER DATA TYPES CONFIGURATION
 *
 */
export const getNotificationsFilterDataTypes = (): FilterDataType[] => {
	const envName = environment.app;
	let filterDataTypes: FilterDataType[] = [];
	const baseFilterDataTypes: FilterDataType[] = [
		COMMON_FILTER_DATA_TYPES['createdAt'],
		{
			key: 'sent_to',
			text: 'Sent to count',
			field: 'number',
			condition: 'number',
		},
		{
			key: 'state',
			text: 'Status',
			field: 'multiselect',
			condition: 'text',
			options: [
				{
					value: 'DRAFT',
					label: 'Draft',
				},
				{
					value: 'PENDING',
					label: 'Pending',
				},
				{
					value: 'LIVE',
					label: 'Live',
				},
				{
					value: 'EXPIRED',
					label: 'Expired',
				},
			],
		},
		COMMON_FILTER_DATA_TYPES['title'],
	];

	switch (envName) {
		case 'Y-Stop':
			filterDataTypes = baseFilterDataTypes;
			break;
		case 'Jotto':
			filterDataTypes = [COMMON_FILTER_DATA_TYPES['channel.name'], ...baseFilterDataTypes];
			break;
		default:
			if (isSuperAdmin()) {
				filterDataTypes = [COMMON_FILTER_DATA_TYPES['channel.name'], ...baseFilterDataTypes];
			} else {
				filterDataTypes = baseFilterDataTypes;
			}
			break;
	}

	return sortFilterDataTypeAlphabetically(filterDataTypes);
};

/*
 *
 * COLLECTIBLES STATE FILTER DATA TYPES CONFIGURATION
 *
 */
export const getUsersFilterDataTypes = (): FilterDataType[] => {
	const envName = environment.app;
	let filterDataTypes: FilterDataType[] = [];
	const baseFilterDataTypes: FilterDataType[] = [
		{
			key: 'email',
			text: 'Email',
			field: 'text',
			condition: 'text',
		},
		{
			key: 'first_name',
			text: 'First Name',
			field: 'text',
			condition: 'text',
		},
		{
			key: 'last_name',
			text: 'Last Name',
			field: 'text',
			condition: 'text',
		},
		COMMON_FILTER_DATA_TYPES['createdAt'],
		{
			key: 'role.name',
			text: 'Role',
			field: 'multiselect',
			condition: 'text',
			options: [...getRoleOptionsByUserRole()],
		},
	];
	const extraFilterDataTypes: FilterDataType[] = [
		{
			key: 'blocked',
			text: 'Deactivated',
			field: 'select',
			condition: 'boolean',
			options: [
				{
					label: 'True',
					value: true,
				},
				{
					label: 'False',
					value: false,
				},
			],
		},
		COMMON_FILTER_DATA_TYPES['updatedAt'],
	];

	switch (envName) {
		case 'Tobacco Atlas':
			filterDataTypes = baseFilterDataTypes;
			break;
		default:
			filterDataTypes = [...baseFilterDataTypes, ...extraFilterDataTypes];
			break;
	}

	return sortFilterDataTypeAlphabetically(filterDataTypes);
};

/*
 *
 * USERS STATE FILTER DATA TYPES CONFIGURATION
 *
 */
export const getCollectiblesFilterDataTypes = (): FilterDataType[] => {
	const envName = environment.app;
	let filterDataTypes: FilterDataType[] = [];
	const baseFilterDataTypes: FilterDataType[] = [
		{
			key: 'comments.text',
			text: 'Comments',
			field: 'text',
			condition: 'text',
		},
		{
			key: 'archived',
			text: 'Archived',
			field: 'select',
			condition: 'boolean',
			options: [
				{
					label: 'False',
					value: false,
				},
				{
					label: 'True',
					value: true,
				},
			],
		},
		{
			key: 'user.id',
			text: 'Curbed',
			field: 'select',
			condition: 'boolean',
			options: [
				{
					label: 'False',
					value: false,
				},
				{
					label: 'True',
					value: true,
				},
			],
			collectionType: 'collectibles',
		},
		{
			key: 'video_length',
			text: 'Duration',
			field: 'number',
			condition: 'number',
		},
		{
			key: 'flagged',
			text: 'Flagged',
			field: 'select',
			condition: 'boolean',
			options: [
				{
					label: 'False',
					value: false,
				},
				{
					label: 'True',
					value: true,
				},
			],
		},
		COMMON_FILTER_DATA_TYPES['createdAt'],
		{
			key: 'tags.name',
			text: 'Tags',
			field: 'multiselect',
			condition: 'text',
			entityName: 'tag',
			fieldName: 'name',
			options: [],
		},
		{
			key: 'type',
			text: 'Type',
			field: 'multiselect',
			condition: 'text',
			options: [
				{
					value: 'stream',
					label: 'Video',
				},
				...(envName === 'Jotto'
					? [
						{
							value: 'audio',
							label: 'Audio',
						},
						{
							value: 'text',
							label: 'Text',
						}
					]
					: []),
				...(envName === 'Y-Stop'
					? [
						{
							value: 'police_report',
							label: 'Police Report',
						},
						{
							value: 'report',
							label: 'Report',
						},
					]
					: []),
				...(envName === 'Mobile Justice'
					? [
						{
							value: 'report',
							label: 'Report',
						},
					]
					: []),
			],
		},
		{
			key: 'seen',
			text: 'Viewed',
			field: 'select',
			condition: 'boolean',
			options: [
				{
					label: 'False',
					value: false,
				},
				{
					label: 'True',
					value: true,
				},
			],
		}/*,
		{
			key: 'location',
			text: 'Location',
			field: 'location',
			condition: 'location',
		},*/
	];
	const momentsFilterDataType: FilterDataType[] = [
		{
			key: 'moments.name',
			text: 'Utterance',
			field: 'moment',
			condition: 'contains',
		},
	];

	const notificationFilterDataType: FilterDataType[] = [
		{
			key: 'notification.title',
			text: 'Prompt',
			condition: 'text',
			field: 'multiselect',
			entityName: 'notification',
			fieldName: 'title',
			options: [],
		},
	];

	const contactInfoFilterDataType: FilterDataType[] = [
		{
			key: 'contact_info',
			text: 'Contact Info',
			field: 'text',
			condition: 'contains',
		}
	];

	switch (envName) {
		case 'Y-Stop':
			filterDataTypes = baseFilterDataTypes;
			break;
		case 'Mobile Justice':
			filterDataTypes = [
				COMMON_FILTER_DATA_TYPES['channel.name'],
				...momentsFilterDataType,
				...baseFilterDataTypes,
				...contactInfoFilterDataType,
				{
					key: 'data',
					text: 'Report',
					field: 'text',
					condition: 'contains',
				}
			];
			break;
		default:
			filterDataTypes = [
				COMMON_FILTER_DATA_TYPES['channel.name'],
				...momentsFilterDataType, // temporary added AI filters for other environment for testing in jotto v4, should remove later.
				...baseFilterDataTypes,
				...notificationFilterDataType
			];
			break;
	}

	return sortFilterDataTypeAlphabetically(filterDataTypes);
};

/*
 *
 * ARTICLES STATE FILTER DATA TYPES CONFIGURATION
 *
 */
export const getArticlesFilterDataTypes = (): FilterDataType[] => {
	const envName = environment.app;
	let filterDataTypes: FilterDataType[] = [];
	const baseFilterDataTypes: FilterDataType[] = [COMMON_FILTER_DATA_TYPES['createdAt'], COMMON_FILTER_DATA_TYPES['title']];
	const projectNameFilterDataType: FilterDataType = {
		key: 'project.name',
		text: 'Project',
		field: 'multiselect',
		condition: 'text',
		entityName: 'project',
		fieldName: 'name',
		options: [],
	};

	if (isSuperAdmin()) {
		filterDataTypes = [projectNameFilterDataType, ...baseFilterDataTypes];
	} else {
		filterDataTypes = [...baseFilterDataTypes];
	}

	return sortFilterDataTypeAlphabetically(filterDataTypes);
};
