import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { TobaccoItem, TobaccoItemService, FilterDataType, Filter, COMMON_FILTER_DATA_TYPES } from '@portals/api';

import { finalize, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Pagination } from '@portals/ui';
import { ServerDatatableServiceInterface } from './server-datatable-service.interface';

interface StateModel {
	isLoading: boolean;
	data: TobaccoItem[];
	selected?: TobaccoItem;
	pagination: Pagination;
	searchFilter: object;
	filters: Filter[];
	filterParameter: object;
	defaultFilterParameter: object;
	filterDataTypes: FilterDataType[];
	totalRows: number;
	sort: string;
	populate: string[];
}

@StateRepository()
@State<StateModel>({
	name: 'reports',
	defaults: {
		isLoading: false,
		totalRows: 0,
		data: [],
		pagination: {
			'pagination[start]': 0,
			'pagination[limit]': 25,
		},
		sort: 'updatedAt:desc',
		populate: ['deep'],
		searchFilter: {},
		filters: JSON.parse(sessionStorage.getItem('reports.filters') || '[]'),
		filterParameter: JSON.parse(sessionStorage.getItem('reports.filterParameter') || '{}'),
		defaultFilterParameter: {},
		filterDataTypes: [
			{
				key: 'brand',
				text: 'Brand',
				field: 'async-dropdown',
				entityName: 'tobacco-item',
				fieldName: 'brand',
				condition: 'text',
			},
			{
				key: 'report_location.country.name',
				text: 'Country',
				field: 'async-dropdown',
				condition: 'text',
				entityName: 'country',
				fieldName: 'name',
			},
			{
				key: 'report_location.country.currency.symbol',
				text: 'Currency',
				field: 'async-dropdown',
				entityName: 'currency',
				fieldName: 'name',
				condition: 'text',
			},
			{
				key: 'report_location.name',
				text: 'Location',
				field: 'text',
				condition: 'text',
			},
			{
				key: 'price',
				text: 'Price',
				field: 'number',
				condition: 'number',
			},
			{
				key: 'quantity',
				text: 'Quantity',
				field: 'number',
				condition: 'number',
			},
			COMMON_FILTER_DATA_TYPES['createdAt'],
			{
				key: 'tax_included',
				text: 'Tax Included',
				field: 'select',
				condition: 'boolean',
				options: [
					{
						label: 'False',
						value: false,
					},
					{
						label: 'True',
						value: true,
					},
				],
			},
			{
				key: 'user.username',
				text: 'User',
				field: 'text',
				condition: 'text',
			},
		],
	},
})
@Injectable()
export class ReportsState extends NgxsDataRepository<StateModel> implements ServerDatatableServiceInterface {
	@Computed()
	get isLoading() {
		return this.snapshot.isLoading;
	}

	@Computed()
	get hasData() {
		return !!this.snapshot.data;
	}

	@Computed()
	get data() {
		return this.snapshot.data;
	}

	@Computed()
	get item() {
		return this.snapshot.selected;
	}

	@Computed()
	get pagination() {
		return this.snapshot.pagination;
	}

	@Computed()
	get searchFilter() {
		return this.snapshot.searchFilter;
	}

	@Computed()
	get filterParameter() {
		return this.snapshot.filterParameter;
	}

	@Computed()
	get defaultFilterParameter() {
		return this.snapshot.defaultFilterParameter;
	}

	@Computed()
	get totalRows() {
		return this.snapshot.totalRows;
	}

	@Computed()
	get sort() {
		return this.snapshot.sort;
	}

	@Computed()
	get populate() {
		return this.snapshot.populate;
	}

	constructor(private tobaccoItemService: TobaccoItemService) {
		super();
	}

	setPagination(pagination: Pagination) {
		this.patchState({ pagination });
		this.load().subscribe();
	}

	setSort(key: string, order: string) {
		const parsedOrder = `${!order ? 'desc' : order}`;
		const sort = `${key}:${parsedOrder}`;
		this.patchState({ sort });
		this.load().subscribe();
	}

	searchFields(keys: string[], value?: string): void {
		const searchFilter: any = {};

		if (value) {
			keys.forEach((key, index) => {
				searchFilter[`_where[_or][${index}][${key}_contains]`] = value;
			});
		}

		this.patchState({ searchFilter });
		this.load().subscribe();
	}

	setFilterParameter(filterParameter: object): void {
		this.patchState({ filterParameter });
		this.load().subscribe();
	}

	setFilters(filterParameter: object): void {
		this.patchState({ filterParameter });
	}

	setDefaultFilterParameter(defaultFilterParameter: object): void {
		this.patchState({ defaultFilterParameter });
	}

	setPopulate(populate: string[]): void {
		this.patchState({ populate });
	}

	clear() {
		this.patchState({
			sort: 'updatedAt:desc',
			pagination: {
				'pagination[start]': 0,
				'pagination[limit]': 25,
			},
		});
	}

	load(initialLoad = false) {
		this.patchState({ isLoading: true });

		if (initialLoad) {
			this.clear();
		}

		return this.tobaccoItemService.find({ params: { ...this.getCombinedParams() } }).pipe(
			tap(({ data, total }) => {
				this.patchState({ data, totalRows: total });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	loadItem(id: number): Observable<TobaccoItem> {
		this.patchState({ isLoading: true });

		return this.tobaccoItemService.findOne(id).pipe(
			tap((data) => {
				this.patchState({ selected: data });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	delete(id: number) {
		this.patchState({ isLoading: true });

		return this.tobaccoItemService.delete(id).pipe(mergeMap(() => this.load()));
	}

	getCombinedParams(): object {
		let filterParameter: any = { ...this.filterParameter };
		// combine defaultFilterParameter to filterParameter
		filterParameter = {
			filters: {
				...(filterParameter?.filters || {}),
				...this.defaultFilterParameter,
			},
		};

		return {
			sort: this.sort,
			populate: this.populate,
			...this.pagination,
			...this.searchFilter,
			...filterParameter,
		};
	}

	getAllData() {
		const params = { ...this.getCombinedParams(), 'pagination[limit]': -1 };
		return this.tobaccoItemService.find({ params });
	}
}
