import { Injectable } from '@angular/core';
import { DataProvider, RequestOptions, StrapiService } from '../../core';
import { Notification, NotificationRecents } from './notification.model';
import { AuthState } from '@portals/store';
import { IResponseChart } from './notification.model';
@Injectable()
export class JottoNotificationService extends StrapiService<Notification> {
	protected override model = 'notifications';

	constructor(dataProvider: DataProvider, private user: AuthState) {
		super(dataProvider);
	}

	findWhere(params: Record<string, string | number | boolean>) {
		return this.dataProvider.get<Notification[]>(`notification-feeds/${this.user.data.id}`, { params });
	}

	notify<U>(id: number, options?: RequestOptions) {
		return this.dataProvider.post<U>(`${this.model}/${id}/notify`, options);
	}

	override findOne(id: number, options?: RequestOptions) {
		return this.dataProvider.get<Notification>(`${this.model}/${id}`, {
			skipInterceptor: true,
		});
	}

	reorderNotification(oldIndex: number, newIndex: number, channelId: number) {
		return this.dataProvider.put<Notification>(`/notifications/changeOrder?channelId=${channelId}&oldIndex=${oldIndex}&newIndex=${newIndex}`);
	}

	getReponseChartData(timeframe: string, params: Record<string, string | number | boolean | undefined>) {
		return this.dataProvider.get<IResponseChart>(`/notifications/response-charts/${timeframe}`, { params });
	}
	validateCustomLink(link: string) {
		return this.dataProvider.get<Notification[]>(`/notifications/customLinkValidator/${link}`);
	}
	getMostRecentActiveFeed() {
		return this.dataProvider.get<NotificationRecents[]>('/notifications/recent_active');
	}
	reactions<U>(id: number, payload: FormData) {
		return this.dataProvider.put<U>(`${this.model}/${id}/reactions`, payload);
	}
	responseCount<U>(id: number) {
		return this.dataProvider.get<U>(`${this.model}/${id}/responses`);
	}
}
