import { Directive, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { getChildRoute } from '@portals/utils';
import { AopNavigationService } from 'aop-routing';
import { filter, switchMap, tap } from 'rxjs/operators';
import { BaseComponent } from './base-component';

@Directive()
export class AppBaseComponent extends BaseComponent implements OnInit {
	constructor(public titleService: Title, public router: Router, public activatedRoute: ActivatedRoute, protected aopNavigationService: AopNavigationService) {
		super();
	}

	ngOnInit() {
		this.setupTitleBasedOnRoute();
	}

	private setupTitleBasedOnRoute() {
		this.subscribe(
			this.router.events.pipe(
				filter((event) => event instanceof NavigationEnd),
				switchMap(() => {
					return getChildRoute(this.activatedRoute).data;
				}),
				tap((data) => {
					this.titleService.setTitle(data['title']);
				})
			)
		);
	}
}
