export const getObjectValue = <T>(o: T, s: string) => {
	s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
	s = s.replace(/^\./, ''); // strip a leading dot
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let result: any = o;
	s.split('.').forEach((v) => {
		if (!result) {
			return;
		}
		result = result[v];
	});

	return result;
};

export const isString = (param: any) => typeof param === 'string';
