export type NestedKey = {
	key: string;
	text: string;
};

export type FilterParameter = {
	text: string;
	key: string;
	value: string;
	nestedKey?: string;
};

export type Filter = {
	parameter: FilterParameter;
	condition: string;
	value: string;
	rangeValue: string[];
	subValue?: string[];
	field: string;
	collectionType?: string;
};

export type FilterOption = {
	value: string | boolean | number;
	label: string;
};

export type FilterColumn = {
	key: string;
	text: string;
	disabled: boolean;
};

export type FilterDataType = {
	key: string;
	nestedKeys?: NestedKey[];
	text: string;
	field: 'text' | 'select' | 'multiselect' | 'date' | 'date-range' | 'duration' | 'toggle' | 'number' | 'location' | 'moment' | 'async-dropdown';
	condition: 'text' | 'number' | 'duration' | 'date' | 'boolean' | 'contains' | 'location';
	entityName?: string;
	fieldName?: string;
	options?: string[] | FilterOption[];
	collectionType?: string;
};
