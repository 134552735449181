import { Conditional, Filter } from '.';
import * as moment from 'moment';
import { getObjectValue, isString } from '@portals/utils';

export class IsConditional implements Conditional {
	filter(data: any, input: Filter): any[] {
		return data.filter((item: any) => {
			const val: string = getObjectValue(item, input.parameter.key);
			const param1 = `${val}`.trim().toLowerCase();
			const param2 = input.value.trim().toLowerCase();

			return param1 === param2;
		});
	}
}

export class IsNotConditional implements Conditional {
	filter(data: any, input: Filter): any[] {
		return data.filter((item: any) => {
			const val = getObjectValue(item, input.parameter.key);
			const param1 = `${val}`.trim().toLowerCase();
			const param2 = input.value.trim().toLowerCase();

			return param1 !== param2;
		});
	}
}

export class ContainsConditional implements Conditional {
	filter(data: any, input: Filter): any[] {
		return data.filter((item: any) => {
			const val = getObjectValue(item, input.parameter.key);
			const param1 = `${val}`.trim().toLowerCase();
			const param2 = input.value.trim().toLowerCase();

			return param1.includes(param2);
		});
	}
}

export class ContainsNotConditional implements Conditional {
	filter(data: any, input: Filter): any[] {
		return data.filter((item: any) => {
			const val = getObjectValue(item, input.parameter.key);
			const param1 = `${val}`.trim().toLowerCase();
			const param2 = input.value.trim().toLowerCase();
			return !param1.includes(param2);
		});
	}
}

export class EqualsConditional implements Conditional {
	filter(data: any, input: Filter): any[] {
		return data.filter((item: any) => {
			const val: number | string = getObjectValue(item, input.parameter.key);
			const param1 = isString(val) ? `${val}`.trim().toLowerCase() : val;
			const param2 = isString(val) ? input.value.trim().toLowerCase() : parseFloat(input.value.trim());

			if (input.parameter.value === 'datetime') {
				const date1 = moment(param1).format('LL');
				const date2 = moment(param2).format('LL');
				return moment(date1).valueOf() === moment(date2).valueOf();
			}
			return param1 === param2;
		});
	}
}

export class EqualsNotConditional implements Conditional {
	filter(data: any, input: Filter): any[] {
		return data.filter((item: any) => {
			const val = getObjectValue(item, input.parameter.key);
			const param1 = `${val}`.trim().toLowerCase();
			const param2 = input.value.trim().toLowerCase();

			if (input.parameter.value === 'datetime') {
				const date1 = moment(param1).format('LL');
				const date2 = moment(param2).format('LL');
				return moment(date1).valueOf() !== moment(date2).valueOf();
			}
			return param1 !== param2;
		});
	}
}

export class LessThanConditional implements Conditional {
	filter(data: any, input: Filter): any[] {
		return data.filter((item: any) => {
			const val: number | string = getObjectValue(item, input.parameter.key);
			const param1 = isString(val) ? `${val}`.trim().toLowerCase() : val;
			const param2 = isString(val) ? input.value.trim().toLowerCase() : parseFloat(input.value.trim());

			if (input.parameter.value === 'datetime') {
				const date1 = moment(param1).format('LL');
				const date2 = moment(param2).format('LL');
				return moment(date1).valueOf() < moment(date2).valueOf();
			}
			return param1 < param2;
		});
	}
}

export class GreaterThanConditional implements Conditional {
	filter(data: any, input: Filter): any[] {
		return data.filter((item: any) => {
			const val: number | string = getObjectValue(item, input.parameter.key);
			const param1 = isString(val) ? `${val}`.trim().toLowerCase() : val;
			const param2 = isString(val) ? input.value.trim().toLowerCase() : parseFloat(input.value.trim());

			if (input.parameter.value === 'datetime') {
				const date1 = moment(param1).format('LL');
				const date2 = moment(param2).format('LL');
				return moment(date1).valueOf() > moment(date2).valueOf();
			}
			return param1 > param2;
		});
	}
}

export class LessThanOrEqualsConditional implements Conditional {
	filter(data: any, input: Filter): any[] {
		return data.filter((item: any) => {
			const val: number | string = getObjectValue(item, input.parameter.key);
			const param1 = isString(val) ? `${val}`.trim().toLowerCase() : val;
			const param2 = isString(val) ? input.value.trim().toLowerCase() : parseFloat(input.value.trim());

			if (input.parameter.value === 'datetime') {
				const date1 = moment(param1).format('LL');
				const date2 = moment(param2).format('LL');
				return moment(date1).valueOf() <= moment(date2).valueOf();
			}
			return val <= param2;
		});
	}
}

export class GreaterThanOrEqualsConditional implements Conditional {
	filter(data: any, input: Filter): any[] {
		return data.filter((item: any) => {
			const val: number | string = getObjectValue(item, input.parameter.key);
			const param1 = isString(val) ? `${val}`.trim().toLowerCase() : val;
			const param2 = isString(val) ? input.value.trim().toLowerCase() : parseFloat(input.value.trim());

			if (input.parameter.value === 'datetime') {
				const date1 = moment(param1).format('LL');
				const date2 = moment(param2).format('LL');
				return moment(date1).valueOf() >= moment(date2).valueOf();
			}
			return param1 >= param2;
		});
	}
}
