import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Collectible, CollectibleService, Collection, Tag, FilterDataType, Filter, getCollectiblesFilterDataTypes } from '@portals/api';
import { MapData } from '@portals/api';
import { finalize, mergeMap, take, tap } from 'rxjs/operators';
import { RequestOptions } from 'libs/portals/api/src/lib/core';
import { Observable } from 'rxjs';
import { Pagination } from '@portals/ui';
import { AuthState } from './auth.state';
import { environment } from '@portals/environments';

interface StateModel {
	isLoading: boolean;
	data: Collectible[];
	selected?: Collectible;
	pagination: Pagination;
	searchFilter: object;
	filters: Filter[];
	filterParameter: object;
	defaultFilterParameter: object;
	filterDataTypes: FilterDataType[];
	totalRows: number;
	sort: string;
	populate: string[];
	collectionId?: string;
	mutedUsers?: string[];
}

@StateRepository()
@State<StateModel>({
	name: 'collectibles',
	defaults: {
		isLoading: false,
		totalRows: 0,
		data: [],
		pagination: {
			'pagination[start]': 0,
			'pagination[limit]': 25,
		},
		sort: 'createdAt:desc',
		populate: [],
		searchFilter: {},
		filters: JSON.parse(sessionStorage.getItem('collectibles.filters') || '[]'),
		filterParameter: JSON.parse(sessionStorage.getItem('collectibles.filterParameter') || '{}'),
		defaultFilterParameter: {},
		filterDataTypes: [...getCollectiblesFilterDataTypes()],
		mutedUsers: JSON.parse(sessionStorage.getItem('collectibles.mutedUsers') || '[]'),
	},
})
@Injectable()
export class CollectiblesState extends NgxsDataRepository<StateModel> {
	@Computed()
	get isLoading() {
		return this.snapshot.isLoading;
	}

	@Computed()
	get hasData() {
		return !!this.snapshot.data;
	}

	@Computed()
	get data() {
		return this.snapshot.data;
	}

	@Computed()
	get item() {
		return this.snapshot.selected;
	}

	@Computed()
	get pagination() {
		return this.snapshot.pagination;
	}

	@Computed()
	get searchFilter() {
		return this.snapshot.searchFilter;
	}

	@Computed()
	get filterParameter() {
		return this.snapshot.filterParameter;
	}

	@Computed()
	get defaultFilterParameter() {
		return this.snapshot.defaultFilterParameter;
	}

	@Computed()
	get totalRows() {
		return this.snapshot.totalRows;
	}

	@Computed()
	get sort() {
		return this.snapshot.sort;
	}

	@Computed()
	get populate() {
		return this.snapshot.populate;
	}

	constructor(private collectibleService: CollectibleService, private user: AuthState) {
		super();
	}

	clear() {
		this.patchState({
			collectionId: undefined,
			sort: 'createdAt:desc',
			pagination: {
				'pagination[start]': 0,
				'pagination[limit]': 25,
			},
		});
	}

	load(initialLoad = false, externalFilterParam: null | object = null) {
		const params = !externalFilterParam ? { ...this.getCombinedParams() } : { ...this.getCombinedParams(true), ...externalFilterParam };
		this.patchState({ isLoading: true });

		if (initialLoad) {
			this.clear();
		}

		return this.collectibleService.find({ params }).pipe(
			tap(({ data, total }) => {
				this.patchState({ data, totalRows: total });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	loadItem(id: number): Observable<Collectible> {
		this.patchState({ isLoading: true });

		return this.collectibleService.findOne(id).pipe(
			tap((data) => {
				this.patchState({ selected: data });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	loadDetails(id: number, options?: RequestOptions) {
		this.patchState({ isLoading: true });

		return this.collectibleService.findOne(id, options).pipe(
			tap((data) => {
				this.patchState({ selected: data });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	loadByUUID(uuid: string) {
		this.patchState({ isLoading: true });

		return this.collectibleService
			.find({
				params: { 'filters[uuid][$eq]': uuid },
				skipInterceptor: true,
			})
			.pipe(
				tap(({ data }) => {
					this.patchState({ selected: data[0] });
				}),
				finalize(() => {
					this.patchState({ isLoading: false });
				})
			);
	}

	create(payload: any) {
		this.patchState({ isLoading: true });

		return this.collectibleService.create(payload).pipe(
			tap((res) => {
				// console.log('RESPONSE', res);
			})
		);
	}

	update(id: number, payload: Partial<Collectible>) {
		this.patchState({ isLoading: true });

		return this.collectibleService.update(id, payload).pipe(mergeMap(() => this.load()));
	}

	removeTag(collectible: Collectible, tagId: number): Observable<Collectible> {
		this.patchState({ isLoading: true });
		const tags = (collectible.tags as Tag[])?.filter(({ id }) => id !== tagId);

		return this.collectibleService
			.update<Collectible>(collectible.id!, {
				tags,
			})
			.pipe(
				finalize(() => {
					this.patchState({ isLoading: false });
				})
			);
	}

	removeCollection(collectible: Collectible, collectionId: number): Observable<Collectible> {
		this.patchState({ isLoading: true });
		const collections = (collectible.collections as Collection[])?.filter(({ id }) => id !== collectionId);

		return this.collectibleService
			.update<Collectible>(collectible.id!, {
				collections,
			})
			.pipe(
				finalize(() => {
					this.patchState({ isLoading: false });
				})
			);
	}

	setPagination(pagination: Pagination) {
		this.patchState({ pagination });
		this.load().subscribe();
	}

	setSort(key: string, order: string, isLoad = true) {
		const parsedOrder = `${!order ? 'desc' : order}`;
		const sort = `${key}:${parsedOrder}`;
		this.patchState({ sort });
		if (isLoad) this.load().subscribe();
	}

	searchFields(keys: string[], value?: string): void {
		const searchFilter: any = {};

		if (value) {
			keys.forEach((key, index) => {
				const newIndex = this.snapshot.collectionId ? index + 1 : index;
				searchFilter[`_where[_or][${newIndex}][${key}_contains]`] = value;
			});
		}

		this.patchState({
			searchFilter: {
				...this.searchFilter,
				...searchFilter,
			},
		});
		this.load().subscribe();
	}

	setFilterParameter(filterParameter: object): void {
		this.patchState({ filterParameter });
		this.load().subscribe();
	}

	setDefaultFilterParameter(defaultFilterParameter: object): void {
		this.patchState({ defaultFilterParameter });
	}

	setPopulate(populate: string[]): void {
		this.patchState({ populate });
	}

	setCollectionId(collectionId: string) {
		const searchFilter: any = {};
		searchFilter[`_where[_or][0][collections.id]`] = collectionId;
		this.patchState({
			searchFilter: {
				...this.searchFilter,
				...searchFilter,
			},
		});
	}

	setFilters(filters: Filter[]): void {
		this.patchState({ filters });
	}

	setMutedUsers(mutedUsers: string[]) {
		this.patchState({ mutedUsers });
	}

	getCombinedParams(isExternalFilter = false): object {
		let filterParameter: any = { ...this.filterParameter };
		// combine defaultFilterParameter to filterParameter
		filterParameter = {
			filters: {
				...this.defaultFilterParameter,
				...(filterParameter?.filters || {}),
			},
		};
		// filter by project handling
		if (environment.app === 'Jotto') {
			if (this.user?.data?.project?.id) {
				filterParameter = {
					filters: {
						...(filterParameter?.filters || {}),
						project: this.user.data.project.id,
					},
				};
			}
		}

		// 		return {
		// 			sort: this.sort,
		// 			populate: this.populate,
		// 			...this.pagination,
		// 			...this.searchFilter,
		// 			...filterParameter,
		// 		};

		const param = {
			sort: this.sort,
			populate: this.populate,
			...this.pagination,
			...this.searchFilter,
		};

		return !isExternalFilter ? { ...param, ...filterParameter } : param;
	}

	getAllData() {
		const params = { ...this.getCombinedParams(), 'pagination[limit]': -1 };
		return this.collectibleService.find({ params });
	}

	getDashboardData() {
		return this.collectibleService.customGetPath({ path: 'dashboard' });
	}
	putTranscriptionText(uuid: string, data: any) {
		return this.collectibleService.putTranscriptionText(uuid, data);
	}
	getTranscriptionText(uuid: string) {
		return this.collectibleService.getFullTranscription(uuid);
	}
	getMapsData() {
		return this.collectibleService.getMapsData();
	}
}
