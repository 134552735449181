import { Injectable } from '@angular/core';
import { Comment } from './comment.model';
import { DataProvider, RequestOptions, StrapiService } from '../../core';
import { Observable } from 'rxjs';

@Injectable()
export class CommentService extends StrapiService<Comment> {
	protected override model: string = 'comments';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}

	override create(payload: FormData | Omit<Comment, 'id' | 'user'>, options?: RequestOptions): Observable<Comment> {
		return this.dataProvider.post(this.model, payload, options);
	}
}
