import { StrapiMedia } from '@portals/api';
import { ReachEventCompany } from '../reach-event-company';
import { ReachLab } from '../reach-lab';

export type ReachEvent = {
	id: number;
	uuid: string;
	title: string;
	banner: StrapiMedia;
	lab: ReachLab;
	companies: Array<ReachEventCompany>;
	description: string;
	schedule: string;
	schedule_date: string;
	schedule_timezone: string;
	location: string;
	url: number;
	// created_at: any;
	start_date: any;
	end_date: any;
	attendees: Array<any>;
	active: boolean;
	publishedAt: Date;
	createdAt: Date;
	updatedAt: Date;
};

export type ReachEventData = {
	image?: string;
	value?: Notification;
};
