import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { API_PROVIDERS } from './services';
import { EnvironmentConfig, ENV_CONFIG } from './tokens';

@NgModule({
	imports: [HttpClientModule],
	providers: [...API_PROVIDERS],
})
export class PortalsApiModule {
	static forRoot(config: EnvironmentConfig): ModuleWithProviders<PortalsApiModule> {
		return {
			ngModule: PortalsApiModule,
			providers: [
				{
					provide: ENV_CONFIG,
					useValue: config,
				},
			],
		};
	}
}
