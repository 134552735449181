export enum RequestContext {
	beta = 'Jotto_Beta',
	jottoContact = 'Jotto_Contact',
	q2Contact = 'Quadrant2_Contact',
	bystanderContact = 'Bystander_Contact',
	jottoFree = 'Jotto_Free',
	jottoEssential = 'Jotto_Essential',
	jottoPro = 'Jotto_Pro',
	jottoPremium = 'Jotto_Premium',
}

export type Request = {
	name: string;
	email: string;
	company?: string;
	message?: string;
	phone?: string;
	context: RequestContext;
};
