import { Injectable, NgZone } from '@angular/core';
import * as AWS from 'aws-sdk';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
AWS.config.update({
	accessKeyId: 'AKIAVWVXYAFN2IR743VD',
	secretAccessKey: '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',
});
const s3 = new AWS.S3();

@Injectable({
	providedIn: 'root',
})
export class AWSUploadService {
	private uploadCompleteSource = new BehaviorSubject<boolean>(false);
	uploadComplete$: Observable<boolean> = this.uploadCompleteSource.asObservable();

	constructor(private ngZone: NgZone, private router: Router) {}

	notifyUploadComplete(isComplete: boolean) {
		// console.log('Have you completed uploading', isComplete);
		this.uploadCompleteSource.next(isComplete);
	}

	async uploadVideoToS3(videoData: Blob, fileName: string) {
		const params = {
			Bucket: 'g3-jotto',
			Key: fileName,
			Body: videoData,
			ContentType: 'video/webm', // Adjust based on your video type
		};

		return new Promise((resolve, reject) => {
			s3.upload(params, (err: any, data: any) => {
				if (!err) {
					this.ngZone.run(() => {
						this.notifyUploadComplete(true);
						resolve(true);
					});
				}
			});
		});
	}
}
