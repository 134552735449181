import { Language } from '../lang';
import { Project } from '../project';
import { StrapiMedia } from '../strapi-media';

export type Article = {
	id?: number;
	uuid: string;
	title: string;
	description: any;
	content: any;
	image: StrapiMedia;
	project: Project;
	language: Language;
	parent?: Article;
	sort_order: number;
};
