import { Injectable } from '@angular/core';
import { DataProvider, StrapiService } from '../../core';
import { Channel, TopChannel } from './channel.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { fromDataResponse } from '../api.utils';
@Injectable()
export class ChannelService extends StrapiService<Channel> {
	protected override model = 'channels';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}

	override findOneByUUID(uuid: string) {
		return this.dataProvider.get<Channel>(`channel/${uuid}`, {
			skipInterceptor: true,
		});
	}
	getTopFive() {
		return this.dataProvider.get<TopChannel[]>(`top_10`);
	}
	findByHash(channelHash: string): Observable<Channel> {
		return this.dataProvider
			.get<Channel[]>(`channels?filters[share_url][$contains]=${channelHash}&populate=deep`, {
				skipInterceptor: true,
			})
			.pipe(map((result: any) => result.data[0]));
	}
	momentByChannel(channelID: string) {
		return this.dataProvider.get<any>(`/channels/moments/${channelID}`, { skipInterceptor: true });
	}

	getStrapiChannel(channelId: number | string): Observable<Channel> {
		return fromDataResponse(
			this.dataProvider.get<Channel>(`channels/${channelId}`, {
				// params: { update_count: true },
			})
		);
	}
}
