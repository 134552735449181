import { User } from '../user';
import { Collectible } from '../collectible';
import { Notification } from '../notification';

export type Comment = {
	id: number;
	text: string;
	user?: User;
	collectible: Collectible | number;
	type: string;
	notification?: Notification;
	createdAt?: Date;
};
