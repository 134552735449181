import { Injectable } from '@angular/core';
import { ReportLocation } from './report-location-model';
import { DataProvider } from '../../core';
import { map } from 'rxjs/operators';
import { flattenAttributes } from '../api.utils';

@Injectable()
export class ReportLocationService {
	constructor(private dataService: DataProvider) {}

	getReportLocations() {
		return this.dataService.get<ReportLocation[]>('report-locations?pagination[limit]=-1&populate[0]=tobacco_items&populate[1]=location&populate[2]=country').pipe(map(flattenAttributes));
	}

	getReportLocationReports(id: number) {
		return this.dataService.get<ReportLocation>(`report-locations/${id}?populate=deep`).pipe(map(flattenAttributes));
	}

	putReportLocation(location: any, data: any) {
		return this.dataService.put<ReportLocation>(`report-locations/${location}`, data);
	}
}
