import { Injectable } from '@angular/core';
import { DataProvider, StrapiService } from '../../core';
import { Collection } from './collection.model';

@Injectable()
export class CollectionService extends StrapiService<Collection> {
	protected override model: string = 'collections';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}
}
