import { Project } from '../project';
import { ReachLab } from '../reach-lab';
import { StrapiMedia } from '../strapi-media';

export type UserRole = {
	id: number;
	name: string;
	type: string;
	description: string;
};

export interface User {
	avatar?: StrapiMedia;
	id: number;
	first_name?: string;
	last_name?: string;
	username?: string;
	email: string;
	role: UserRole;
	uuid?: string;
	confirmed?: boolean;
	blocked?: boolean;
	project?: Project;
	lab?: ReachLab;
	password: string;
}

export type CreateUserPayload = {
	username?: string;
	first_name: string;
	last_name: string;
	email: string;
	password: string;
	role: UserRole;
	project?: Project;
	lab?: ReachLab;
	confirmed?: boolean;
};
