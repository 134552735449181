import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Follow, FollowService, RequestOptions } from '@portals/api';

import { finalize, mergeMap, tap } from 'rxjs/operators';

interface StateModel {
	isLoading: boolean;
	data: Follow[];
	selected: Follow;
}

@StateRepository()
@State<StateModel>({
	name: 'follows',
})
@Injectable()
export class FollowsState extends NgxsDataRepository<StateModel> {
	@Computed()
	get isLoading() {
		return this.snapshot.isLoading;
	}

	@Computed()
	get hasData() {
		return !!this.snapshot.data;
	}

	@Computed()
	get data() {
		return this.snapshot.data;
	}

	@Computed()
	get item() {
		return this.snapshot.selected;
	}

	constructor(private followService: FollowService) {
		super();
	}

	load(options: RequestOptions = {}) {
		this.patchState({ isLoading: true });

		return this.followService.find(options).pipe(
			tap(({ data }) => {
				this.patchState({ data });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	findOne(id: number) {
		return this.followService.findOne(id);
	}

	create(payload: FormData) {
		this.patchState({ isLoading: true });

		return this.followService.create(payload).pipe(mergeMap(() => this.load()));
	}

	update(id: number, formData: FormData) {
		this.patchState({ isLoading: true });

		return this.followService.update(id, formData).pipe(mergeMap(() => this.load()));
	}

	delete(id: number) {
		this.patchState({ isLoading: true });

		return this.followService.unfollowChannel(id).pipe(mergeMap(() => this.load()));
	}

	getAllData() {
		const params = { sort: 'updatedAt:desc', 'pagination[limit]': -1 };
		return this.followService.find({ params });
	}
}
