import { ReachEvent } from '../reach-event';
import { ReachEventCompany } from '../reach-event-company';
import { ReachLab } from '../reach-lab';

export type ReachEventCompanyLead = {
	id: number;
	contact_name: string;
	contact_email: string;
	contact_notes: string;
	contact_phone: number;
	company: ReachEventCompany;
	event: ReachEvent;
	lab: ReachLab;
};

export type ReachEventCompanyLeadPayload = {
	contact_name: string;
	contact_email: string;
	contact_notes: string;
	contact_phone: number;
	company: number;
	event: number;
	lab: number;
};
