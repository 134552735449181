import { Notification, StrapiMedia } from '@portals/api';
import { Project } from '@portals/api';
export type TopChannel = {
	noti_count: number;
	notification_id: number;
	noti_img: string;
	name: string;
	color?: string;
};
export type Channel = {
	id: number;
	uuid?: string;
	name: string;
	slug: string;
	description: string;
	share_url: string;
	external_url: string;
	is_private: boolean;
	is_active: boolean;
	image: StrapiMedia;
	project: Project;
	notification_count?: number;
	notifications?: Notification[];
	view_count?: number;
	num_followers?: number;
	num_reactions?: number;
	num_link_clicks?: number;
	cta: string;
	publishedAt: Date;
	createdAt: Date;
	updatedAt: Date;
};
export type CreateChannelPayload = {
	name: string;
	description: string;
	external_url: string;
	is_active: boolean;
};
