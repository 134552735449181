import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AbilityModule } from '@casl/angular';

// import {
// 	SpeechRecognitionModule,
// 	SpeechRecognitionService,
// 	RxSpeechRecognitionService
//  } from '@kamiazya/ngx-speech-recognition';

// libs
import { throwIfAlreadyLoaded } from '@portals/utils';
import { PortalsStoreModule } from '@portals/store';

// app
import { environment } from '@portals/environments';
import { AopRoutingModule } from 'aop-routing';
import { CORE_PROVIDERS, HttpInterceptorService, LogService } from './services';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { Ability, PureAbility } from '@casl/ability';
import { ToastrModule } from 'ngx-toastr';
import { WindowRef } from './base';
import { HttpErrorInterceptorService } from './services/http-error-interceptor.service';
import { CookieModule } from 'ngx-cookie';
import { OverlayModule } from '@angular/cdk/overlay';
import { DIALOG_DATA } from './services/dialog.tokens';
import { MatDialogModule } from '@angular/material/dialog';

/**
 * DEBUGGING
 */
LogService.DEBUG.LEVEL_4 = !environment.production;
// optionally debug http
// LogService.DEBUG_HTTP.enable = true;
// LogService.DEBUG_HTTP.includeRequestBody = true;
// LogService.DEBUG_HTTP.includeResponse = true;
// optionally debug analytics (will log out all data before its sent)
// LogService.DEBUG_ANALYTICS = true;

export const BASE_PROVIDERS: unknown[] = [
	...CORE_PROVIDERS,
	{ provide: DIALOG_DATA, useValue: {} },
	{
		provide: APP_BASE_HREF,
		useValue: '/',
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpInterceptorService,
		multi: true,
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpErrorInterceptorService,
		multi: true,
	},
	{ provide: Ability, useValue: new Ability() },
	{ provide: PureAbility, useExisting: Ability },
	// SpeechRecognitionService,
	// RxSpeechRecognitionService
];

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, `./assets/i18n/`, '.json');
}

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		PortalsStoreModule,
		AbilityModule,
		OverlayModule,
		MatDialogModule,
		CookieModule.withOptions(),
		AopRoutingModule.forRoot({ expirementNav: true }),
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-left',
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		// SpeechRecognitionModule.withConfig({
		// 	continuous: true,
		// 	lang: 'en-US',
		// 	interimResults: true,
		// 	maxAlternatives: 10000000
		//  })
	],
})
export class PortalsCoreModule {
	constructor(
		@Optional()
		@SkipSelf()
		parentModule: PortalsCoreModule,
		translate: TranslateService
	) {
		throwIfAlreadyLoaded(parentModule, 'PortalsCoreModule');

		// ensure default platform language is set
		// translate.use(translate.getBrowserLang());
		translate.setDefaultLang('en');
	}

	// configuredProviders: *required to configure WindowService and others per platform
	static forRoot(configuredProviders: Array<any>): ModuleWithProviders<PortalsCoreModule> {
		return {
			ngModule: PortalsCoreModule,
			providers: [
				...BASE_PROVIDERS,
				...configuredProviders,
				WindowRef,
				{
					provide: Window,
					useValue: window,
				},
			],
		};
	}
}
