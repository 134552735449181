import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Role, RolesService } from '@portals/api';

import { finalize, mergeMap, tap } from 'rxjs/operators';

interface StateModel {
	isLoading: boolean;
	data: Role[];
	selected: Role;
}

@StateRepository()
@State<StateModel>({
	name: 'roles',
})
@Injectable()
export class RolesState extends NgxsDataRepository<StateModel> {
	@Computed()
	get isLoading() {
		return this.snapshot.isLoading;
	}

	@Computed()
	get hasData() {
		return !!this.snapshot.data;
	}

	@Computed()
	get data() {
		return this.snapshot.data;
	}

	@Computed()
	get item() {
		return this.snapshot.selected;
	}

	constructor(private rolesService: RolesService) {
		super();
	}

	load() {
		this.patchState({ isLoading: true });
		return this.rolesService.findRoles({ skipInterceptor: true }).pipe(
			tap((data) => {
				this.patchState({ data });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	create(formData: FormData) {
		this.patchState({ isLoading: true });

		return this.rolesService.create(formData).pipe(mergeMap(() => this.load()));
	}
}
