import { Injectable } from '@angular/core';
import { Device, RegisterDevicePayload } from '@portals/api';

import { DataProvider, StrapiService } from '../../core';
import { User } from './user.model';

@Injectable()
export class UserService extends StrapiService<User> {
	protected override model: string = 'users';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}
}
