import { Injectable } from '@angular/core';
import { ConfirmationPayload, ConfirmationResponsePayload, ForgotPasswordPayload, ForgotPasswordResponse, LoginPayload, LoginResponse, RegisterPayload, RegisterResponse, ResetPasswordPayload, ResetPasswordResponse } from '.';
import { DataProvider, RequestOptions } from '../../core';
import { User } from '../user';

@Injectable()
export class AuthService {
	constructor(private dataService: DataProvider) {}

	login(payload: LoginPayload) {
		return this.dataService.post<LoginResponse>('auth/local', payload, {
			skipInterceptor: true,
		});
	}

	registerUser(payload: RegisterPayload) {
		return this.dataService.post<RegisterResponse>(
			'auth/local/register',
			{
				...payload,
				username: payload.email,
			},
			{
				skipInterceptor: true,
			}
		);
	}

	forgotPassword(payload: ForgotPasswordPayload, options: RequestOptions) {
		return this.dataService.post<ForgotPasswordResponse>('/auth/forgot-password', payload, { responseType: 'json', skipInterceptor: true });
	}

	resetPassword(payload: ResetPasswordPayload) {
		return this.dataService.post<ResetPasswordResponse>('/auth/reset-password', payload, {
			skipInterceptor: true,
		});
	}

	confirmUser(payload: ConfirmationPayload) {
		return this.dataService.get<ConfirmationResponsePayload>(`/auth/email-confirmation?token=${payload.token}&email=${payload.email}`, {
			skipInterceptor: true,
		});
	}

	getUser(token: string) {
		return this.dataService.get<User>(`/users/me?populate=deep`, {
			skipInterceptor: true,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}
}
