import { Injectable } from '@angular/core';
import { DataProvider, StrapiService } from '../../core';
import { Tag } from './tag.model';

@Injectable()
export class TagService extends StrapiService<Tag> {
	protected override model: string = 'tags';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}
}
