import { Pagination } from '@portals/ui';
import { Observable } from 'rxjs';

export interface ServerDatatableServiceInterface {
	setPagination(pagination: Pagination): void;

	searchFields(keys: string[], value?: string): void;

	setFilterParameter(filterParameter: object): void;

	setDefaultFilterParameter(filterParameter: object): void;

	/**
	 *   getCombinedParams(): object {
	 *     return {
	 *       ...this.sort,
	 *       ...this.queryFilter,
	 *       ...this.searchFilter,
	 *       ...this.customFilters
	 *     };
	 *   }
	 */
	getCombinedParams(): object;

	clear(): void;
}
