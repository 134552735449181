import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentConfig, ENV_CONFIG } from '../tokens';
import { formatBaseUrl, formatUrlPath } from '../services/api.utils';
import { InterceptorSkipHeader } from '@portals/core';

export type RequestOptions = {
	responseType?: any;
	params?:
		| HttpParams
		| {
				[param: string]: string | number | string[];
		  }
		| any;
	headers?:
		| HttpHeaders
		| {
				[header: string]: string | string[];
		  };
	skipInterceptor?: boolean;
};

@Injectable({ providedIn: 'root' })
export class DataProvider {
	public baseUrl: string;

	constructor(@Inject(ENV_CONFIG) config: EnvironmentConfig, private http: HttpClient) {
		this.baseUrl = formatBaseUrl(config.environment.baseUrl);
	}

	get<T>(path: string, options?: RequestOptions): Observable<T> {
		const requestOptions = this.formatRequestOptions(options);

		return this.http.get<T>(`${this.baseUrl}/${formatUrlPath(path)}`, requestOptions);
	}

	post<T>(path: string, body?: unknown, options?: RequestOptions): Observable<T> {
		const requestOptions = this.formatRequestOptions(options);

		return this.http.post<T>(`${this.baseUrl}/${formatUrlPath(path)}`, body, requestOptions);
	}

	put<T>(path: string, body?: unknown, options?: RequestOptions): Observable<T> {
		const requestOptions = this.formatRequestOptions(options);

		return this.http.put<T>(`${this.baseUrl}/${formatUrlPath(path)}`, body, requestOptions);
	}

	delete(path: string) {
		return this.http.delete(`${this.baseUrl}/${formatUrlPath(path)}`);
	}

	private formatRequestOptions(options?: RequestOptions) {
		return options?.skipInterceptor
			? {
					...options,
					headers: {
						...options.headers,
						[InterceptorSkipHeader]: 'true',
					},
			  }
			: options;
	}
}
