import { Observable, from, map } from 'rxjs';

export interface DataResponse {
	data: any[];
	total: number;
}

export interface RawDataResponse {
	data: { id: number; attributes: object }[];
	meta: {
		pagination: {
			page: number;
			pageSize: number;
			pageCount: number;
			total: number;
		};
	};
}

export const formatBaseUrl = (url: string) => (url.substr(-1) === '/' ? url.slice(0, -1) : url);

export const formatUrlPath = (path: string) => (path[0] === '/' ? path.slice(1) : path);

// strapi v4 data parser
export const flattenAttributes = (obj: any) => {
	for (let key in obj) {
		if (key === 'attributes') {
			for (let subKey in obj[key]) {
				obj[subKey] = typeof obj[key][subKey] === 'object' ? flattenAttributes(obj[key][subKey]) : obj[key][subKey];
			}
			delete obj[key];
		} else if (typeof obj[key] === 'object') {
			if (key === 'data') {
				obj = flattenAttributes(obj[key]);
			} else {
				obj[key] = flattenAttributes(obj[key]);
			}
		}
	}

	return obj;
};

export const parseResponse = (response: RawDataResponse | any): DataResponse => {
	if (!response.data) {
		return {
			data: response,
			total: response && response[0] && response[response.length - 1]?.total ? response.pop().total : 0,
		};
	}

	return {
		data: response.data.map((item: any) => ({
			...flattenAttributes(item),
		})),
		total: response.meta.pagination.total,
	};
};

export const fromDataResponse = (request: Observable<any>) => {
	return from(request).pipe(map((response: RawDataResponse) => flattenAttributes(response?.data)));
};
