import { Channel } from '../channel';
import { User } from '../user';

export type Follow = {
	id?: number;
	user: User;
	channel: Channel;
	unsubscribed: boolean;
	publishedAt: Date;
	createdAt: Date;
	updatedAt: Date;
};

export type GetFollowingChannelsResponse = Follow[];
