import { Injectable } from '@angular/core';

import { DataProvider, StrapiService } from '../../core';
import { Article } from './article.model';

@Injectable()
export class ArticleService extends StrapiService<Article> {
	protected override model: string = 'articles';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}
}
