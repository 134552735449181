import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Request, RequestsService } from '@portals/api';

import { finalize, mergeMap, tap } from 'rxjs/operators';

interface StateModel {
	isLoading: boolean;
	data: Request[];
	selected: Request;
}

@StateRepository()
@State<StateModel>({
	name: 'requests',
})
@Injectable()
export class RequestsState extends NgxsDataRepository<StateModel> {
	@Computed()
	get isLoading() {
		return this.snapshot.isLoading;
	}

	@Computed()
	get hasData() {
		return !!this.snapshot.data;
	}

	@Computed()
	get data() {
		return this.snapshot.data;
	}

	@Computed()
	get item() {
		return this.snapshot.selected;
	}

	constructor(private requestsService: RequestsService) {
		super();
	}

	create(form: any) {
		this.patchState({ isLoading: true });

		return this.requestsService.create(form, { skipInterceptor: true }).pipe(
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}
}
