import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '@portals/environments';

type Context = {
	id: string;
	text: string;
	short_code?: string;
	wikidata?: string;
};

export type Feature = {
	id: string;
	type: string;
	place_type: string[];
	relevance: number;
	properties: {
		accuracy: string;
		'override:postcode': string;
	};
	place_name: string;
	center: string[];
	geometry: {
		type: string;
		coordinates: string[];
	};
	context: Context[];
};

type MapBoxOutput = {
	attribution: string;
	features: Feature[];
	type: string;
	query: string[];
};

@Injectable()
export class MapBoxService {
	constructor(private http: HttpClient) {}

	searchKeyword(keyword: string) {
		const url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
		return this.http.get(url + keyword + `.json?&types=address,region,place&access_token=${environment.mapBoxToken}`).pipe(
			map(({ features }: MapBoxOutput | any) => {
				return features;
			})
		);
	}
}
