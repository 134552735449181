import { Country, ReportLocation, StrapiMedia } from '@portals/api';
import { Location } from '../../core';

export type TobaccoItem = {
	id: number;
	brand: string;
	quantity: number;
	price: number;
	tax_included: boolean;
	images: StrapiMedia[];
	tobacco_type: string;
	notes: string;
	location: Location;
	report_location: ReportLocation;
};

export enum TobaccoType {
	chew = 'Chew',
	shisha = 'Shisha',
	cigarretes = 'Cigarretes',
}
