import { Form } from '@portals/api';

export enum FieldType {}
export enum DataType {}

export type FormField = {
	field: {
		name: string;
		field_type: FieldType;
		data_type: DataType;
	};
	order: number;
	label: string;
	key: string;
	form: Form;
};
