import { Injectable } from '@angular/core';
import { Report } from './report-model';
import { DataProvider, StrapiService } from '../../core';
// import { map } from 'rxjs/operators';
@Injectable()
export class ReportService extends StrapiService<Report> {
	protected override model: string = 'reports';
	constructor(dataprovider: DataProvider) {
		super(dataprovider);
	}
}
