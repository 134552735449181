import { Injectable } from '@angular/core';

import { DataProvider, StrapiService } from '../../core';
import { ReachEventCompanyLead } from './company-lead.model';

@Injectable()
export class ReachEventCompanyLeadService extends StrapiService<ReachEventCompanyLead> {
	protected override model: string = 'company-leads';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}
}
