import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { CreateUserPayload, User, UserService, FilterDataType, Filter, getUsersFilterDataTypes, Device, FollowService, Follow, Channel, DeviceService } from '@portals/api';
import { finalize, mergeMap, take, tap } from 'rxjs/operators';
import { Pagination } from '@portals/ui';
import { AuthState } from './auth.state';
import { DeviceDetectorService } from 'ngx-device-detector';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { environment } from '@portals/environments';
import { of, Subject } from 'rxjs';

function compare(a: any, b: any) {
	if (a.id === 1) {
		return -1; // put object with id 1 at index 0
	} else if (b.id === 1) {
		return 1; // put object with id 1 at index 0
	} else {
		return 0; // maintain the order of the rest of the objects
	}
}

interface StateModel {
	isLoading: boolean;
	data: User[];
	follows: Follow[];
	selected?: User;
	pagination: Pagination;
	searchFilter: object;
	filters: Filter[];
	filterParameter: object;
	defaultFilterParameter: object;
	filterDataTypes: FilterDataType[];
	totalRows: number;
	sort: string;
	populate: string[];
}

@StateRepository()
@State<StateModel>({
	name: 'users',
	defaults: {
		isLoading: false,
		totalRows: 0,
		data: [],
		follows: [],
		pagination: {
			start: 0,
			limit: 25,
		},
		sort: 'updatedAt:desc',
		populate: ['deep'],
		searchFilter: {},
		filters: JSON.parse(sessionStorage.getItem('users.filters') || '[]'),
		filterParameter: JSON.parse(sessionStorage.getItem('users.filterParameter') || '{}'),
		defaultFilterParameter: {},
		filterDataTypes: [...getUsersFilterDataTypes()],
	},
})
@Injectable()
export class UsersState extends NgxsDataRepository<StateModel> {
	message!: any;

	@Computed()
	get isLoading() {
		return this.snapshot.isLoading;
	}

	@Computed()
	get hasData() {
		return !!this.snapshot.data;
	}

	@Computed()
	get data() {
		return this.snapshot.data;
	}

	@Computed()
	get follows() {
		return this.snapshot.follows;
	}

	@Computed()
	get item() {
		return this.snapshot.selected;
	}

	@Computed()
	get pagination() {
		return this.snapshot.pagination;
	}

	@Computed()
	get searchFilter() {
		return this.snapshot.searchFilter;
	}

	@Computed()
	get filterParameter() {
		return this.snapshot.filterParameter;
	}

	@Computed()
	get defaultFilterParameter() {
		return this.snapshot.defaultFilterParameter;
	}

	@Computed()
	get totalRows() {
		return this.snapshot.totalRows;
	}

	@Computed()
	get sort() {
		return this.snapshot.sort;
	}

	@Computed()
	get populate() {
		return this.snapshot.populate;
	}

	constructor(private userService: UserService, private followService: FollowService, private user: AuthState, private userDeviceService: DeviceService, private deviceService: DeviceDetectorService) {
		super();
	}

	clear() {
		this.patchState({
			sort: 'updatedAt:desc',
			pagination: {
				start: 0,
				limit: 25,
			},
		});
	}

	load(initialLoad = false): any {
		this.patchState({ isLoading: true });

		if (initialLoad) {
			this.clear();
		}

		return this.userService.find({ params: { ...this.getCombinedParams() } }).pipe(
			tap((data) => {
				const v3data: any = data;
				this.patchState({
					data: v3data.data,
					totalRows: v3data.total,
				});
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	create(payload: CreateUserPayload) {
		this.patchState({ isLoading: true });

		return this.userService.create({ ...payload, username: payload.email }).pipe(mergeMap(() => this.load()));
	}

	getUser(id: number) {
		this.patchState({ isLoading: true });

		return this.userService.findOneById(id).pipe(
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	updateUser(id: number, payload: Partial<User> | FormData) {
		this.patchState({ isLoading: true });

		return this.userService.update(id, payload).pipe(
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	setPagination(pagination: Pagination) {
		this.patchState({ pagination });
		this.load().subscribe();
	}

	setSort(key: string, order: string) {
		const parsedOrder = `${!order ? 'desc' : order}`;
		const sort = `${key}:${parsedOrder}`;
		this.patchState({ sort });
		this.load().subscribe();
	}

	searchFields(keys: string[], value?: string): void {
		const searchFilter: any = {};

		if (value) {
			keys.forEach((key, index) => {
				searchFilter[`_where[_or][${index}][${key}_contains]`] = value;
			});
		}

		this.patchState({ searchFilter });
		this.load().subscribe();
	}

	setFilterParameter(filterParameter: object): void {
		this.patchState({ filterParameter });
		this.load().subscribe();
	}

	setFilters(filters: Filter[]): void {
		this.patchState({ filters });
	}

	setDefaultFilterParameter(defaultFilterParameter: object): void {
		this.patchState({ defaultFilterParameter });
	}

	setPopulate(populate: string[]): void {
		this.patchState({ populate });
	}

	getCombinedParams(role: string[] = ['aclu_admin', 'agent', 'intake_viewer', 'affiliate_admin', 'lab_admin', 'project_manager' || 'beta_user', 'super_admin']): object {
		if (environment.app === 'Tobacco Atlas') role.push('guest', 'authenticated', 'analyst');
		let filterParameter: any = { ...this.filterParameter };
		// combine defaultFilterParameter to filterParameter
		filterParameter = {
			filters: {
				...this.defaultFilterParameter,
				...(filterParameter?.filters || {}),
			},
		};

		// filter by role.type handling
		if (!filterParameter?.filters?.role?.type) {
			filterParameter = {
				filters: {
					...(filterParameter?.filters || {}),
					role: { type: { $in: role } },
				},
			};
		}
		// filter by project handling
		if (this.user?.data?.project?.id) {
			filterParameter = {
				filters: {
					...(filterParameter?.filters || {}),
					project: this.user.data.project.id,
				},
			};
		}

		return {
			sort: this.sort,
			populate: this.populate,
			...this.pagination,
			...this.searchFilter,
			...filterParameter,
		};
	}

	// Webapp state functions
	createMobileUser(payload: CreateUserPayload) {
		this.patchState({ isLoading: true });
		let email = `${payload.username}@strapi.com`;

		return this.userService.create({ ...payload, email: email }, { skipInterceptor: true });
	}

	listen() {
		const messaging = getMessaging();
		onMessage(messaging, (payload) => {
			this.message = payload;
		});
	}

	async registerDeviceToken() {
		// console.log('Getting Firebase Token');
		const messaging = await getMessaging();
		return getToken(messaging, { vapidKey: environment.firebase.vapidKey })
			.then((token) => {
				return token;
			})
			.catch((err: any) => {
				return of(err);
			});
	}

	registerDevice(token: any) {
		const payload: Omit<Device, 'id' | 'user' | 'push_token'> = {
			model: this.deviceService.getDeviceInfo().device,
			app_version: <string>environment.version,
			device_os: this.deviceService.getDeviceInfo().os,
		};

		return this.userDeviceService
			.registerDevice({
				...payload,
				push_token: token,
				user: this.user.data.id,
			})
			.pipe(
				finalize(() => {
					this.listen();
				})
			);
	}

	loadFollowingChannels() {
		this.patchState({ isLoading: true });
		return this.followService.getFollowingChannels().pipe(
			tap((follows: any) => {
				this.patchState({ follows: follows.sort(compare) });
			})
		);
	}

	followChannel(channel: number, device: number) {
		this.patchState({ isLoading: true });

		return this.followService.followChannel(channel, this.user.data.id, device).pipe(mergeMap(() => this.loadFollowingChannels()));
	}

	getAllData() {
		const params = { ...this.getCombinedParams(), 'pagination[limit]': -1 };
		return this.userService.find({ params });
	}
}
