import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Notification, ChannelService, JottoNotificationService, FilterDataType, Filter, getNotificationsFilterDataTypes } from '@portals/api';

import { finalize, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Pagination } from '@portals/ui';
import { ServerDatatableServiceInterface } from './server-datatable-service.interface';
import { AuthState } from './auth.state';

interface StateModel {
	isLoading: boolean;
	data: Notification[];
	selected?: Notification;
	pagination: Pagination;
	searchFilter: object;
	filters: Filter[];
	filterParameter: object;
	defaultFilterParameter: object;
	filterDataTypes: FilterDataType[] | any[];
	totalRows: number;
	sort: string;
	populate: string[];
	latestFeeds?: Notification[];
}

@StateRepository()
@State<StateModel>({
	name: 'notifications',
	defaults: {
		isLoading: false,
		totalRows: 0,
		data: [],
		pagination: {
			'pagination[start]': 0,
			'pagination[limit]': 25,
		},
		sort: 'updatedAt:desc',
		populate: ['deep'],
		searchFilter: {},
		filters: JSON.parse(sessionStorage.getItem('notifications.filters') || '[]'),
		filterParameter: JSON.parse(sessionStorage.getItem('notifications.filterParameter') || '{}'),
		defaultFilterParameter: {},
		filterDataTypes: [...getNotificationsFilterDataTypes()],
	},
})
@Injectable()
export class NotificationsState extends NgxsDataRepository<StateModel> implements ServerDatatableServiceInterface {
	@Computed()
	get isLoading() {
		return this.snapshot.isLoading;
	}

	@Computed()
	get hasData() {
		return !!this.snapshot.data;
	}

	@Computed()
	get latestFeeds() {
		return this.snapshot.latestFeeds;
	}

	@Computed()
	get data() {
		return this.snapshot.data;
	}

	@Computed()
	get item() {
		return this.snapshot.selected;
	}

	@Computed()
	get pagination() {
		return this.snapshot.pagination;
	}

	@Computed()
	get searchFilter() {
		return this.snapshot.searchFilter;
	}

	@Computed()
	get filterParameter() {
		return this.snapshot.filterParameter;
	}

	@Computed()
	get defaultFilterParameter() {
		return this.snapshot.defaultFilterParameter;
	}

	@Computed()
	get totalRows() {
		return this.snapshot.totalRows;
	}

	@Computed()
	get sort() {
		return this.snapshot.sort;
	}

	@Computed()
	get populate() {
		return this.snapshot.populate;
	}

	getReponseChart(timeframe: string, project?: number) {
		return this.notificationService.getReponseChartData(timeframe, {
			project: project,
		});
	}

	validateCustomLink(link: string) {
		return this.notificationService.validateCustomLink(link);
	}

	constructor(private notificationService: JottoNotificationService, private channelService: ChannelService, private user: AuthState) {
		super();
	}

	setPagination(pagination: Pagination) {
		this.patchState({ pagination });
		this.load().subscribe();
	}

	setSort(key: string, order: string) {
		const parsedOrder = `${!order ? 'desc' : order}`;
		const sort = `${key}:${parsedOrder}`;
		this.patchState({ sort });
		this.load().subscribe();
	}

	searchFields(keys: string[], value?: string): void {
		const searchFilter: any = {};

		if (value) {
			keys.forEach((key, index) => {
				searchFilter[`_where[_or][${index}][${key}_contains]`] = value;
			});
		}

		this.patchState({ searchFilter });
		this.load().subscribe();
	}

	setFilterParameter(filterParameter: object): void {
		this.patchState({ filterParameter });
		this.load().subscribe();
	}

	setDefaultFilterParameter(defaultFilterParameter: object): void {
		this.patchState({ defaultFilterParameter });
	}

	setPopulate(populate: string[]): void {
		this.patchState({ populate });
	}

	clear() {
		this.patchState({
			sort: 'updatedAt:desc',
			pagination: {
				'pagination[start]': 0,
				'pagination[limit]': 25,
			},
		});
	}

	load(initialLoad = false) {
		this.patchState({ isLoading: true });

		if (initialLoad) {
			this.clear();
		}

		return this.notificationService.find({ params: { ...this.getCombinedParams() } }).pipe(
			tap(({ data, total }) => {
				this.patchState({ data, totalRows: total });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	loadLatestFeeds(userId: string) {
		this.patchState({ isLoading: true });

		return this.notificationService.get(`notification-feeds/${userId}`, { params: {} }).pipe(
			tap((data) => {
				this.patchState({ latestFeeds: data });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}
	getTopMostRecentFeeds() {
		return this.notificationService.getMostRecentActiveFeed();
	}
	loadItem(id: number, params?: any): Observable<Notification> {
		this.patchState({ isLoading: true });

		return this.notificationService.findOne(id, { params: { populate: 'deep' } }).pipe(
			tap((data) => {
				this.patchState({ selected: data });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	loadChannelFeed(id: number, limit?: number) {
		this.patchState({ isLoading: true });

		return this.channelService.getStrapiChannel(id).pipe(
			mergeMap(() =>
				limit
					? this.notificationService.findWhere({
							type: 'channel',
							type_id: id,
							_limit: limit,
					  })
					: this.notificationService.findWhere({
							type: 'channel',
							type_id: id,
					  })
			),
			tap((notifications) => {
				this.patchState({ data: notifications });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	create(formData: FormData) {
		this.patchState({ isLoading: true });

		return this.notificationService.create(formData).pipe(
			tap((data) => {
				this.patchState({ data: [data] });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	reactions(id: number, formData: FormData) {
		this.patchState({ isLoading: true });

		return this.notificationService.reactions(id, formData).pipe(mergeMap(() => this.loadItem(id)));
	}

	responses(id: number) {
		return this.notificationService.responseCount(id);
	}

	update(id: number, formData: FormData) {
		this.patchState({ isLoading: true });

		return this.notificationService.update(id + '?populate[duration]=true', formData).pipe(mergeMap(() => this.loadItem(id)));
	}

	notify(id: number) {
		this.patchState({ isLoading: true });

		return this.notificationService.notify(id).pipe(mergeMap(() => this.loadItem(id)));
	}

	reorderNotification(channelId: number, oldIndex: number, newIndex: number) {
		this.patchState({ isLoading: true });

		return this.notificationService.reorderNotification(channelId, oldIndex, newIndex).pipe(mergeMap(() => this.load()));
	}

	delete(id: number) {
		this.patchState({ isLoading: true });

		return this.notificationService.delete(id).pipe(mergeMap(() => this.load()));
	}

	setFilters(filters: Filter[]): void {
		this.patchState({ filters });
	}

	getCombinedParams(): object {
		let filterParameter: any = { ...this.filterParameter };
		// combine defaultFilterParameter to filterParameter
		filterParameter = {
			filters: {
				...this.defaultFilterParameter,
				state: { $not: 'TEMPLATE' },
				...(filterParameter?.filters || {}),
			},
		};

		// filter by project handling
		if (this.user?.data?.project?.id) {
			filterParameter = {
				filters: {
					...(filterParameter?.filters || {}),
					project: { $eq: this.user.data.project.id },
				},
			};
		}

		return {
			sort: this.sort,
			populate: this.populate,
			...this.pagination,
			...this.searchFilter,
			...filterParameter,
		};
	}

	getAllData() {
		const params = { ...this.getCombinedParams(), 'pagination[limit]': -1 };
		return this.notificationService.find({ params });
	}
}
