import { Injectable } from '@angular/core';
import { Channel, Follow, GetFollowingChannelsResponse } from '@portals/api';
import { map, Observable, tap } from 'rxjs';
import { DataProvider, StrapiService } from '../../core';

@Injectable()
export class FollowService extends StrapiService<Follow> {
	protected override model: string = 'follows';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}

	// getFollowingChannels(): Observable<Channel[]> {
	//   return this.dataProvider
	//     .get<GetFollowingChannelsResponse>('follows')
	//     .pipe(
	//       map((data) => {
	//         if (Array.isArray(data)) {
	//           return data.map(({ channel }) => channel);
	//         } else {
	//           return [];
	//         }
	//       })
	//     );
	// }

	getFollowingChannels(): Observable<Channel[]> {
		return this.dataProvider.get<GetFollowingChannelsResponse>('follows?v3=true').pipe(map((data) => data.map(({ channel }) => channel)));
	}

	followChannel(channel: number, user: number, device?: number) {
		return this.dataProvider.post<Follow>(`${this.model}`, {
			channel,
			user,
			device,
		});
	}

	unfollowChannel(channel: number) {
		return this.dataProvider.delete(`follows/${channel}`);
	}
}
