import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Mute, MuteService } from '@portals/api';

import { finalize, mergeMap, tap } from 'rxjs/operators';

interface StateModel {
	isLoading: boolean;
	data: Mute[];
	selected: Mute;
}

@StateRepository()
@State<StateModel>({
	name: 'mutes',
})
@Injectable()
export class MutesState extends NgxsDataRepository<StateModel> {
	@Computed()
	get isLoading() {
		return this.snapshot.isLoading;
	}

	@Computed()
	get hasData() {
		return !!this.snapshot.data;
	}

	@Computed()
	get data() {
		return this.snapshot.data;
	}

	@Computed()
	get item() {
		return this.snapshot.selected;
	}

	constructor(private muteService: MuteService) {
		super();
	}

	load() {
		this.patchState({ isLoading: true });

		return this.muteService.find({ params: { populate: ['user', 'project'] } }).pipe(
			tap(({ data }) => {
				this.patchState({ data });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	create(payload: Mute) {
		this.patchState({ isLoading: true });

		return this.muteService.create({ data: payload } as Mute).pipe(mergeMap(() => this.load()));
	}

	// Remove from mutes state
	remove(payload: any) {
		this.patchState({ isLoading: true });

		return this.muteService.delete(payload).pipe(mergeMap(() => this.load()));
	}
}
