import { TobaccoItem } from '@portals/api';
import { Location } from '../../core';

export type Currency = {
	id?: number;
	name: string;
	symbol: string;
};

export type Country = {
	name: string;
	currency: Currency;
};

export type ReportLocation = {
	id: number;
	currency?: Currency;
	name: string;
	country: Country;
	location: Location;
	tobacco_items: Array<TobaccoItem>;
	notes: string;
};
