import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Project, JottoProjectService, FilterDataType, Filter, COMMON_FILTER_DATA_TYPES } from '@portals/api';
import { Pagination } from '@portals/ui';

import { finalize, mergeMap, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

interface StateModel {
	isLoading: boolean;
	data: Project[];
	selected?: Project;
	pagination: Pagination;
	searchFilter: object;
	filters: Filter[];
	filterParameter: object;
	defaultFilterParameter: object;
	filterDataTypes: FilterDataType[];
	totalRows: number;
	sort: string;
	populate: string[];
}

@StateRepository()
@State<StateModel>({
	name: 'projects',
	defaults: {
		isLoading: false,
		totalRows: 0,
		data: [],
		pagination: {
			'pagination[start]': 0,
			'pagination[limit]': 25,
		},
		sort: 'updatedAt:desc',
		populate: ['deep'],
		searchFilter: {},
		filters: JSON.parse(sessionStorage.getItem('projects.filters') || '[]'),
		filterParameter: JSON.parse(sessionStorage.getItem('projects.filterParameter') || '{}'),
		defaultFilterParameter: {},
		filterDataTypes: [
			{
				key: 'name',
				text: 'Name',
				field: 'text',
				condition: 'text',
			},
			COMMON_FILTER_DATA_TYPES['createdAt'],
		],
	},
})
@Injectable()
export class ProjectsState extends NgxsDataRepository<StateModel> {
	@Computed()
	get isLoading() {
		return this.snapshot.isLoading;
	}

	@Computed()
	get hasData() {
		return !!this.snapshot.data;
	}

	@Computed()
	get data() {
		return this.snapshot.data;
	}

	@Computed()
	get item() {
		return this.snapshot.selected;
	}

	@Computed()
	get pagination() {
		return this.snapshot.pagination;
	}

	@Computed()
	get searchFilter() {
		return this.snapshot.searchFilter;
	}

	@Computed()
	get filterParameter() {
		return this.snapshot.filterParameter;
	}

	@Computed()
	get defaultFilterParameter() {
		return this.snapshot.defaultFilterParameter;
	}

	@Computed()
	get totalRows() {
		return this.snapshot.totalRows;
	}

	@Computed()
	get sort() {
		return this.snapshot.sort;
	}

	@Computed()
	get populate() {
		return this.snapshot.populate;
	}

	constructor(private projectService: JottoProjectService) {
		super();
	}

	clear() {
		this.patchState({
			sort: 'updatedAt:desc',
			pagination: {
				'pagination[start]': 0,
				'pagination[limit]': 25,
			},
		});
	}

	load(initialLoad = false) {
		this.patchState({ isLoading: true });

		if (initialLoad) {
			this.clear();
		}
		return this.projectService.find({ params: { ...this.getCombinedParams() } }).pipe(
			tap(({ data, total }) => {
				this.patchState({ data, totalRows: total });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	loadItem(id: number): Observable<Project> {
		this.patchState({ isLoading: true });

		return this.projectService.findOne(id, { skipInterceptor: true }).pipe(
			tap((data) => {
				this.patchState({ selected: data });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	create(formData: FormData) {
		this.patchState({ isLoading: true });

		return this.projectService.create(formData).pipe(mergeMap(() => this.load()));
	}

	updateProject(id: number, payload: FormData) {
		this.patchState({ isLoading: true });

		return this.projectService.update(id, payload).pipe(
			finalize(() => {
				this.load().subscribe();
				this.patchState({ isLoading: false });
			})
		);
	}

	setPagination(pagination: Pagination) {
		this.patchState({ pagination });
		this.load().subscribe();
	}

	setSort(key: string, order: string) {
		const parsedOrder = `${!order ? 'desc' : order}`;
		const sort = `${key}:${parsedOrder}`;
		this.patchState({ sort });
		this.load().subscribe();
	}

	searchFields(keys: string[], value?: string): void {
		const searchFilter: any = {};

		if (value) {
			keys.forEach((key, index) => {
				searchFilter[`_where[_or][${index}][${key}_contains]`] = value;
			});
		}

		this.patchState({ searchFilter });
		this.load().subscribe();
	}

	setFilterParameter(filterParameter: object): void {
		this.patchState({ filterParameter });
		this.load().subscribe();
	}

	setFilters(filters: Filter[]): void {
		this.patchState({ filters });
	}

	setDefaultFilterParameter(defaultFilterParameter: object): void {
		this.patchState({ defaultFilterParameter });
	}

	setPopulate(populate: string[]): void {
		this.patchState({ populate });
	}

	getCombinedParams(): object {
		let filterParameter: any = { ...this.filterParameter };
		// combine defaultFilterParameter to filterParameter
		filterParameter = {
			filters: {
				...this.defaultFilterParameter,
				...(filterParameter?.filters || {}),
			},
		};

		return {
			sort: this.sort,
			populate: this.populate,
			...this.pagination,
			...this.searchFilter,
			...filterParameter,
		};
	}

	getAllData() {
		const params = { ...this.getCombinedParams(), 'pagination[limit]': -1 };
		return this.projectService.find({ params });
	}
}
