import { Injectable } from '@angular/core';

import { DataProvider, StrapiService } from '../../core';
import { ReachEventCompany } from './reach-event-company.model';

@Injectable()
export class ReachEventCompanyService extends StrapiService<ReachEventCompany> {
	protected override model: string = 'companies';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}
}
