import { Directive, OnDestroy } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';

// libs
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { distinctUntilKeyChanged, filter, takeUntil } from 'rxjs/operators';

@Directive()
export class BaseComponent implements OnDestroy {
	[key: string]: any;
	private _destroy$?: Subject<boolean>;

	get destroy$() {
		if (!this._destroy$) {
			// Perf optimization:
			// since this is likely used as base component everywhere
			// only construct a Subject instance if actually used
			this._destroy$ = new Subject();
		}
		return this._destroy$;
	}

	subscribe<T>(obs: Observable<T>) {
		obs.pipe(takeUntil(this.destroy$)).subscribe();
	}

	fromState<T>(obs: Observable<T>, key: keyof T) {
		return obs.pipe(
			filter((data) => !!data),
			distinctUntilKeyChanged(key)
		);
	}

	ngOnDestroy() {
		if (this._destroy$) {
			this._destroy$.next(true);
			this._destroy$.complete();
		}
	}

	reloadComponent(router: Router) {
		const currentUrl = router.url;
		router.routeReuseStrategy.shouldReuseRoute = () => false;
		router.onSameUrlNavigation = 'reload';
		router.navigate([currentUrl]);
	}

	findInvalidControls(form: any) {
		const invalid = [];
		const controls = form.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name);
			}
		}
	}

	getFormValidationErrors(form: any) {
		let totalErrors = 0;

		Object.keys(form.controls).forEach((key) => {
			const controlErrors: ValidationErrors = form.get(key).errors;
			if (controlErrors != null) {
				totalErrors++;
				Object.keys(controlErrors).forEach((keyError) => {});
			}
		});
	}
}
