import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Collectible, Tag, TagService, RequestOptions } from '@portals/api';

import { finalize, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

interface StateModel {
	isLoading: boolean;
	data: Tag[];
	selected: Tag;
}

@StateRepository()
@State<StateModel>({
	name: 'tags',
})
@Injectable()
export class TagsState extends NgxsDataRepository<StateModel> {
	@Computed()
	get isLoading() {
		return this.snapshot.isLoading;
	}

	@Computed()
	get hasData() {
		return !!this.snapshot.data;
	}

	@Computed()
	get data() {
		return this.snapshot.data;
	}

	@Computed()
	get item() {
		return this.snapshot.selected;
	}

	constructor(private tagService: TagService) {
		super();
	}

	load(options: RequestOptions = {}) {
		this.patchState({ isLoading: true });

		return this.tagService.find(options).pipe(
			tap(({ data }) => {
				this.patchState({ data });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	loadItem(id: number): Observable<Tag> {
		this.patchState({ isLoading: true });

		return this.tagService.findOne(id).pipe(
			tap((data) => {
				this.patchState({ selected: data });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	addCollectible(collectible: number, tag: Tag): Observable<Tag> {
		this.patchState({ isLoading: true });
		const currentCollectibles = ((tag.collectibles || []) as Collectible[])?.map((i) => i.id);

		// If the collectible doesn't already exist we include it in the array
		const collectibles = currentCollectibles.includes(collectible) ? currentCollectibles : [...currentCollectibles, collectible];

		return this.tagService
			.update<Tag>(tag.id!, {
				data: { collectibles },
			})
			.pipe(
				finalize(() => {
					this.patchState({ isLoading: false });
				})
			);
	}

	removeCollectible(collectible: number, tag: Tag): Observable<Tag> {
		this.patchState({ isLoading: true });
		const collectibles = ((tag.collectibles || []) as Collectible[])?.filter(({ id }) => id !== collectible).map(({ id }) => id);

		return this.tagService
			.update<Tag>(tag.id!, {
				data: { collectibles },
			})
			.pipe(
				finalize(() => {
					this.patchState({ isLoading: false });
				})
			);
	}

	create(payload: Tag, collectible: number) {
		this.patchState({ isLoading: true });

		const body: any = { data: { ...payload, collectibles: [collectible] } };

		return this.tagService.create(body).pipe(mergeMap(() => this.load()));
	}
}
