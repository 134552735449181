import { animate, state, style, transition, trigger } from '@angular/animations';

// SLIDE IN
export const slideIn = trigger('slideIn', [state('void', style({ transform: 'translateX(-150%)' })), transition(':enter', animate(500, style({ transform: 'translateX(0)' })))]);

export const slideIn1 = trigger('slideIn1', [state('void', style({ transform: 'translateX(-150%)' })), transition(':enter', animate(1000, style({ transform: 'translateX(0)' })))]);

export const slideIn2 = trigger('slideIn2', [state('void', style({ transform: 'translateX(-150%)' })), transition(':enter', animate(1500, style({ transform: 'translateX(0)' })))]);

export const slideIn3 = trigger('slideIn3', [state('void', style({ transform: 'translateX(-150%)' })), transition(':enter', animate(2000, style({ transform: 'translateX(0)' })))]);

// SLIDE IN (w/ DELAY)
export const slideInDelay = trigger('slideInDelay', [state('void', style({ transform: 'translateX(-150%)' })), transition(':enter', [animate(500, style({ transform: 'translateX(0)' })), animate(500, style('*'))])]);

export const slideInDelay1 = trigger('slideInDelay1', [state('void', style({ transform: 'translateX(-150%)' })), transition(':enter', [animate(1000, style({ transform: 'translateX(0)' })), animate(1000, style('*'))])]);

export const slideInDelay2 = trigger('slideInDelay2', [state('void', style({ transform: 'translateX(-150%)' })), transition(':enter', [animate(1500, style({ transform: 'translateX(0)' })), animate(1500, style('*'))])]);

export const slideInDelay3 = trigger('slideInDelay3', [state('void', style({ transform: 'translateX(-150%)' })), transition(':enter', [animate(2000, style({ transform: 'translateX(0)' })), animate(2000, style('*'))])]);
