import { Component, Renderer2, OnInit } from '@angular/core';
import { AppBaseComponent } from '@portals/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AopNavigationService } from 'aop-routing';

@Component({
	selector: 'jotto-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AppBaseComponent implements OnInit {
	constructor(private renderer: Renderer2, public titleService: Title, public router: Router, public activatedRoute: ActivatedRoute, protected aopNavigationService: AopNavigationService) {
		super(titleService, router, activatedRoute, aopNavigationService);
	}

	ngOnInit() {
		window.resizeTo(800, 600);
		// this.renderer.setStyle(document.body, 'width', '800px'); // set the body width
		// this.renderer.setStyle(document.body, 'height', '600px'); // set the body height
	}
}
