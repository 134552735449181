import { Injectable } from '@angular/core';
import { DataProvider, StrapiService } from '../../core';
import { ReachLab } from './reach-lab.model';

@Injectable()
export class ReachLabService extends StrapiService<ReachLab> {
	protected override model = 'labs';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}
}
