import { Injectable } from '@angular/core';
import { DataProvider, StrapiService } from '../../core';
import { Project } from './project.model';

@Injectable()
export class JottoProjectService extends StrapiService<Project> {
	protected override model = 'projects';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}
}
