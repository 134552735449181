import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataProvider, RequestOptions, StrapiService } from '../../core';
import { Role } from './role.model';

@Injectable()
export class RolesService extends StrapiService<Role> {
	protected override model = 'users-permissions/roles';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}

	findRoles(options?: RequestOptions): Observable<Role[]> {
		return this.dataProvider.get(this.model, options).pipe(map(({ roles }: any) => roles as Role[]));
	}
}
