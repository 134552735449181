import { animate, state, style, transition, trigger } from '@angular/animations';

// SCALE UP
export const scaleUp = trigger('scaleUp', [transition(':enter', [style({ opacity: 0, transform: 'scale(0)' }), animate('500ms', style({ opacity: 1, transform: 'scale(1)' }))])]);

export const scaleUp1 = trigger('scaleUp1', [transition(':enter', [style({ opacity: 0, transform: 'scale(0)' }), animate('1000ms', style({ opacity: 1, transform: 'scale(1)' }))])]);

export const scaleUp2 = trigger('scaleUp2', [transition(':enter', [style({ opacity: 0, transform: 'scale(0)' }), animate('1500ms', style({ opacity: 1, transform: 'scale(1)' }))])]);

export const scaleUp3 = trigger('scaleUp3', [transition(':enter', [style({ opacity: 0, transform: 'scale(0)' }), animate('2000ms', style({ opacity: 1, transform: 'scale(1)' }))])]);

// SCALE UP (w/ DELAY)
export const scaleUpDelay = trigger('scaleUpDelay', [transition(':enter', [style({ opacity: 0, transform: 'scale(0)' }), animate('500ms', style({ opacity: 1, transform: 'scale(1)' })), animate(500)])]);

export const scaleUpDelay1 = trigger('scaleUpDelay1', [transition(':enter', [style({ opacity: 0, transform: 'scale(0)' }), animate('1000ms', style({ opacity: 1, transform: 'scale(1)' })), animate(1000)])]);

export const scaleUpDelay2 = trigger('scaleUpDelay2', [transition(':enter', [style({ opacity: 0, transform: 'scale(0)' }), animate('1500ms', style({ opacity: 1, transform: 'scale(1)' })), animate(1500)])]);

export const scaleUpDelay3 = trigger('scaleUpDelay3', [transition(':enter', [style({ opacity: 0, transform: 'scale(0)' }), animate('2000ms', style({ opacity: 1, transform: 'scale(1)' })), animate(2000)])]);
