import { Injectable } from '@angular/core';
import { DataProvider, StrapiService } from '../../core';
import { ReachEvent } from './reach-event.model';

@Injectable()
export class ReachEventService extends StrapiService<ReachEvent> {
	protected override model = 'events';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}
}
