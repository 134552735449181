import { animate, state, style, transition, trigger } from '@angular/animations';

// SLIDE OUT
export const slideOut = trigger('slideOut', [state('*', style({ transform: 'translateX(0)' })), transition(':leave', animate(500, style({ transform: 'translateX(150%)' })))]);

export const slideOut1 = trigger('slideOut1', [state('*', style({ transform: 'translateX(0)' })), transition(':leave', animate(1000, style({ transform: 'translateX(150%)' })))]);

export const slideOut2 = trigger('slideOut2', [state('*', style({ transform: 'translateX(0)' })), transition(':leave', animate(1500, style({ transform: 'translateX(150%)' })))]);

export const slideOut3 = trigger('slideOut3', [state('*', style({ transform: 'translateX(0)' })), transition(':leave', animate(2000, style({ transform: 'translateX(150%)' }))), transition(':leave', animate(2000, style({ transform: 'translateX(150%)' })))]);

// SLIDE OUT (w/ DELAY)
export const slideOutDelay = trigger('slideOutDelay', [state('*', style({ transform: 'translateX(0)' })), transition(':leave', [animate(500, style({ transform: 'translateX(150%)' })), animate(500, style('*'))])]);

export const slideOutDelay1 = trigger('slideOutDelay1', [state('*', style({ transform: 'translateX(0)' })), transition(':leave', [animate(1000, style({ transform: 'translateX(150%)' })), animate(1000, style('*'))])]);

export const slideOutDelay2 = trigger('slideOutDelay2', [state('*', style({ transform: 'translateX(0)' })), transition(':leave', [animate(1500, style({ transform: 'translateX(150%)' })), animate(1500, style('*'))])]);

export const slideOutDelay3 = trigger('slideOutDelay3', [state('*', style({ transform: 'translateX(0)' })), transition(':leave', [animate(2000, style({ transform: 'translateX(150%)' })), animate(2000, style('*'))])]);
