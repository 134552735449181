import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Collectible, Collection, CollectionService, FilterDataType, Filter, getCollectiblesFilterDataTypes, COMMON_FILTER_DATA_TYPES, RequestOptions } from '@portals/api';

import { finalize, mergeMap, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Pagination } from '@portals/ui';
import { AuthState } from './auth.state';
import { environment } from '@portals/environments';

interface StateModel {
	isLoading: boolean;
	data: Collection[];
	selected?: Collection;
	pagination: Pagination;
	searchFilter: object;
	filters: Filter[];
	filterParameter: object;
	defaultFilterParameter: object;
	filterDataTypes: FilterDataType[];
	subFilters: Filter[];
	subFilterParameter: object;
	subFilterDataTypes: FilterDataType[];
	totalRows: number;
	sort: string;
	populate: string[];
}

@StateRepository()
@State<StateModel>({
	name: 'collections',
	defaults: {
		isLoading: false,
		totalRows: 0,
		data: [],
		pagination: {
			'pagination[start]': 0,
			'pagination[limit]': 25,
		},
		sort: 'createdAt:desc',
		populate: ['deep'],
		searchFilter: {},
		filters: JSON.parse(sessionStorage.getItem('collections.filters') || '[]'),
		filterParameter: JSON.parse(sessionStorage.getItem('collections.filterParameter') || '{}'),
		defaultFilterParameter: {},
		filterDataTypes: [{ key: 'name', text: 'Title', field: 'text', condition: 'text' }, COMMON_FILTER_DATA_TYPES['updatedAt']],
		subFilters: JSON.parse(sessionStorage.getItem('collections.subFilters') || '[]'),
		subFilterParameter: JSON.parse(sessionStorage.getItem('collections.subFilterParameter') || '{}'),
		subFilterDataTypes: [...getCollectiblesFilterDataTypes()],
	},
})
@Injectable()
export class CollectionsState extends NgxsDataRepository<StateModel> {
	@Computed()
	get isLoading() {
		return this.snapshot.isLoading;
	}

	@Computed()
	get hasData() {
		return !!this.snapshot.data;
	}

	@Computed()
	get data() {
		return this.snapshot.data;
	}

	@Computed()
	get item() {
		return this.snapshot.selected;
	}

	@Computed()
	get pagination() {
		return this.snapshot.pagination;
	}

	@Computed()
	get searchFilter() {
		return this.snapshot.searchFilter;
	}

	@Computed()
	get filterParameter() {
		return this.snapshot.filterParameter;
	}

	@Computed()
	get defaultFilterParameter() {
		return this.snapshot.defaultFilterParameter;
	}

	@Computed()
	get subFilterParameter() {
		return this.snapshot.subFilterParameter;
	}

	@Computed()
	get totalRows() {
		return this.snapshot.totalRows;
	}

	@Computed()
	get sort() {
		return this.snapshot.sort;
	}

	@Computed()
	get populate() {
		return this.snapshot.populate;
	}

	constructor(private collectionService: CollectionService, private user: AuthState) {
		super();
	}

	clear() {
		this.patchState({
			sort: 'updatedAt:desc',
			pagination: {
				'pagination[start]': 0,
				'pagination[limit]': 25,
			},
		});
	}

	load(initialLoad = false, options: RequestOptions = {}) {
		this.patchState({ isLoading: true });
		options = !Object.keys(options).length ? { params: { ...this.getCombinedParams() } } : options;

		if (initialLoad) {
			this.clear();
		}
		return this.collectionService.find(options).pipe(
			tap(({ data, total }) => {
				this.patchState({ data, totalRows: total });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	loadItem(id: number): Observable<Collection> {
		this.patchState({ isLoading: true });

		return this.collectionService.findOne(id).pipe(
			tap((data) => {
				this.patchState({ selected: data });
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	addCollectible(collectible: number, collection: Collection): Observable<Collection> {
		this.patchState({ isLoading: true });

		const currentCollectibles = ((collection.collectibles || []) as Collectible[])?.map((i) => i.id);

		// If the collectible doesn't already exist we include it in the array
		const collectibles = currentCollectibles.includes(collectible) ? currentCollectibles : [...currentCollectibles, collectible];

		return this.collectionService
			.update<Collection>(collection.id!, {
				data: { collectibles },
			})
			.pipe(
				finalize(() => {
					this.patchState({ isLoading: false });
				})
			);
	}

	removeCollectible(collectible: number, collection: Collection): Observable<Collection> {
		this.patchState({ isLoading: true });

		const collectibles = ((collection.collectibles || []) as Collectible[])?.filter(({ id }) => id !== collectible).map(({ id }) => id);

		return this.collectionService
			.update<Collection>(collection.id!, {
				data: { collectibles },
			})
			.pipe(
				finalize(() => {
					this.patchState({ isLoading: false });
				})
			);
	}

	create(payload: Collection, collectible: number) {
		this.patchState({ isLoading: true });

		return this.collectionService.create({ ...payload, collectibles: [collectible] }).pipe(mergeMap(() => this.load()));
	}

	updateCollection(id: number, payload: FormData) {
		this.patchState({ isLoading: true });

		return this.collectionService.update(id, payload).pipe(mergeMap(() => this.load()));
	}

	setPagination(pagination: Pagination) {
		this.patchState({ pagination });
		this.load().subscribe();
	}

	setSort(key: string, order: string) {
		const parsedOrder = `${!order ? 'desc' : order}`;
		const sort = `${key}:${parsedOrder}`;
		this.patchState({ sort });
		this.load().subscribe();
	}

	searchFields(keys: string[], value?: string): void {
		const searchFilter: any = {};

		if (value) {
			keys.forEach((key, index) => {
				searchFilter[`_where[_or][${index}][${key}_contains]`] = value;
			});
		}

		this.patchState({ searchFilter });
		this.load().subscribe();
	}

	setFilterParameter(filterParameter: object): void {
		this.patchState({ filterParameter });
		this.load().subscribe();
	}

	setFilters(filters: Filter[]): void {
		this.patchState({ filters });
	}

	setSubFilterParameter(subFilterParameter: object): void {
		this.patchState({ subFilterParameter });
	}

	setSubFilters(subFilters: Filter[]): void {
		this.patchState({ subFilters });
	}

	setDefaultFilterParameter(defaultFilterParameter: object): void {
		this.patchState({ defaultFilterParameter });
	}

	setPopulate(populate: string[]): void {
		this.patchState({ populate });
	}

	getCombinedParams(): object {
		let filterParameter: any = { ...this.filterParameter };
		// combine defaultFilterParameter to filterParameter
		filterParameter = {
			filters: {
				...this.defaultFilterParameter,
				...(filterParameter?.filters || {}),
			},
		};
		// filter by project handling
		if (this.user?.data?.project?.id) {
			filterParameter = {
				filters: {
					...(filterParameter?.filters || {}),
					project: this.user.data.project.id,
				},
			};
		}

		return {
			sort: this.sort,
			populate: this.populate,
			...this.pagination,
			...this.searchFilter,
			...filterParameter,
		};
	}

	getAllData() {
		const params = { ...this.getCombinedParams(), 'pagination[limit]': -1 };
		return this.collectionService.find({ params });
	}
}
