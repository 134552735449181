import { Injectable } from '@angular/core';
import { NarProjectConnections } from '@portals/api';
import { DataProvider } from '../../core';

@Injectable()
export class NarProjectService {
	constructor(private dataService: DataProvider) {}

	getProjectConnections() {
		return this.dataService.get<NarProjectConnections[]>('project-connections');
	}
}
