import { Injectable } from '@angular/core';
import { DataProvider, StrapiService } from '../../core';
import { Mute } from './mute.model';

@Injectable()
export class MuteService extends StrapiService<Mute> {
	protected override model: string = 'mutes';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}
}
