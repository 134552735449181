import { Injectable } from '@angular/core';

import { DataProvider, StrapiService } from '../../core';
import { Language } from './lang.model';

@Injectable()
export class LanguageService extends StrapiService<Language> {
	protected override model: string = 'languages';

	constructor(dataProvider: DataProvider) {
		super(dataProvider);
	}
}
