import { ReachEvent } from '../reach-event';
import { StrapiMedia } from '../strapi-media';

export type ReachEventCompany = {
	id: number;
	uuid: string;
	name: string;
	image: StrapiMedia;
	description: string;
	subtitle: string;
	contact_email: string;
	contact_phone: string;
	website: string;
	materials_url: any;
	event: ReachEvent;
	position: number;
	active: boolean;
};

export type ReachEventCompanyData = {
	image?: string;
	materials_url?: any;
	value?: Notification;
};
