import { ComponentPortal, ComponentType, Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseComponent } from '../base';

@Injectable()
export class PortalService extends BaseComponent {
	/** The panel. */
	private portal$ = new Subject<Portal<any>>();

	/** Retrieves the current panel portal as an `Observable`. */
	get content$(): Subject<Portal<any>> {
		return this.portal$;
	}

	/**
	 * Sets the drawer content.
	 * @param componentRef The component reference used.
	 * @see DrawerService#updateDrawerContent
	 */
	updatePortalContent(componentRef: ComponentType<any>): void {
		const portal = new ComponentPortal(componentRef);
		this.portal$.next(portal);
	}

	/** Resets the current drawer content. */
	clearPortalContent(): void {
		// @ts-ignore
		this.portal$.next(undefined);
	}
}
