import { animate, state, style, transition, trigger } from '@angular/animations';

// FADE IN
export const fadeIn = trigger('fadeIn', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':enter', animate(100, style({ opacity: 1 })))]);

export const fadeIn1 = trigger('fadeIn1', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':enter', animate(1000, style({ opacity: 1 })))]);

export const fadeIn2 = trigger('fadeIn2', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':enter', animate(1500, style({ opacity: 1 })))]);

export const fadeIn3 = trigger('fadeIn3', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':enter', animate(2000, style({ opacity: 1 })))]);

// FADE IN (w/ DELAY)
export const fadeInDelay = trigger('fadeInDelay', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':enter', [animate(500, style({ opacity: 1 })), animate(500, style('*'))])]);

export const fadeInDelay1 = trigger('fadeInDelay1', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':enter', [animate(1000, style({ opacity: 1 })), animate(1000, style('*'))])]);

export const fadeInDelay2 = trigger('fadeInDelay2', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':enter', [animate(1500, style({ opacity: 1 })), animate(1500, style('*'))])]);

export const fadeInDelay3 = trigger('fadeInDelay3', [state('void', style({ opacity: 0 })), state('*', style({ opacity: 1 })), transition(':enter', [animate(2000, style({ opacity: 1 })), animate(2000, style('*'))])]);
