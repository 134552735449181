import { Injectable } from '@angular/core';

import { DataProvider, RequestOptions } from '../../core';
import { buildQueryParameters } from '@portals/api';

@Injectable()
export class DistinctService {
	constructor(private dataProvider: DataProvider) {}

	getDistinctEntity(entityName: string, fieldName: string, options?: RequestOptions) {
		const { params = {}, ...otherProp } = options || {};
		const query: string = buildQueryParameters(params);

		return this.dataProvider.get(`distinct-query?entity=${entityName}&field=${fieldName}&${query}`, otherProp);
	}
}
